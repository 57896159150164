import { useEffect, useState } from 'react';

import { onboardingApi } from '../../../../../api/onboarding';

export const useOnboardingContent = ({
  arrow,
  lang,
}: {
  arrow: string;
  lang: string;
}) => {
  const [content, setContent] = useState();

  const fetchOnboardingContent = async () => {
    const data = await onboardingApi.getOnboardingContent(lang, arrow);
    setContent(data);
  };

  useEffect(() => {
    fetchOnboardingContent();
  }, []);

  return {
    content,
  };
};
