import { AiBotCardLoading } from '../../../../entities/ai-bot-card';
import { UiLoadingSkeleton } from '../../../../shared/ui';

export function ActiveChatLoading() {
  return (
    <div className="active-chat">
      <UiLoadingSkeleton
        style={{
          width: '129px',
          height: '24px',
          margin: '1rem 0',
        }}
      />
      <AiBotCardLoading />
    </div>
  );
}
