const LEADGEN_EM_STORAGE_KEY = 'utm_notif';

export const getQueryParamsFromURI = () => {
  const params: any = {};

  if (typeof window !== 'undefined' && window.location.href.includes('?')) {
    window.location.href
      .split('?')[1]
      .split('&')
      .forEach(p => {
        let [key, value] = p.split('=');
        if (key === 'email') {
          value = value.replaceAll('%20', '+');
        }
        params[key] = decodeURIComponent(value);
      });
  }

  return params;
};

const setUtmNotif = (notif: string) => {
  localStorage.setItem(LEADGEN_EM_STORAGE_KEY, notif);
};

export const getUtmNotif = () => {
  const params = getQueryParamsFromURI();

  if (localStorage.getItem(LEADGEN_EM_STORAGE_KEY)) {
    return localStorage.getItem(LEADGEN_EM_STORAGE_KEY);
  } else if (params[LEADGEN_EM_STORAGE_KEY]) {
    setUtmNotif(params[LEADGEN_EM_STORAGE_KEY]);
    return params[LEADGEN_EM_STORAGE_KEY];
  }

  return null;
};

export const removeUtmNotif = () => {
  localStorage.removeItem(LEADGEN_EM_STORAGE_KEY);
};
