import { isEmpty } from 'lodash-es';
import { useEffect } from 'react';

import { AiBot } from '../../../entities/ai-bot';
import type { AiBotSessionType } from '../../../entities/session';
import { useSessionsListQuery } from '../../../entities/session';
import {
  type AiBotsListType,
  useAiBotsListQuery,
} from '../../../feature/ai-bots-list';
import { MainPageAnalytics } from '../../../shared/analytics';
import { FullPageError } from '../../../shared/errors';
import { UiBaseLayout } from '../../../shared/ui';
import { AiBotsList, AiBotsListLoading } from '../../../widgets/ai-bots-list';
import { ActiveChat, ActiveChatLoading } from './active-chat';

function isSessionsError({
  isError,
  data,
}: {
  isError: boolean;
  data?: Array<AiBotSessionType>;
}) {
  return isError || isEmpty(data);
}

function isListError({
  isError,
  data,
}: {
  isError: boolean;
  data?: AiBotsListType;
}) {
  return isError || isEmpty(data && data.defaults);
}

function AiBotsListContainer({ isLoading }: { isLoading: boolean }) {
  function onCategoryButtonClicked() {
    MainPageAnalytics.onCategoryFilterClick();
  }

  function onClickToBot(bot: AiBot) {
    MainPageAnalytics.onBotClick({
      location: 'explore',
      botName: bot.name,
      botId: bot.id,
      action: 'create_new_chat',
    });
  }

  return isLoading ? (
    <AiBotsListLoading />
  ) : (
    <AiBotsList
      onCategoryButtonClicked={onCategoryButtonClicked}
      onClickToBot={onClickToBot}
    />
  );
}

function ActiveChatContainer({
  aiBotsListLoading,
  aiBotsSessionLoading,
  aiBotsListError,
  aiBotsSessionError,
  sessionsData,
}: {
  aiBotsListLoading: boolean;
  aiBotsSessionLoading: boolean;
  aiBotsListError: boolean;
  aiBotsSessionError: boolean;
  sessionsData?: Array<AiBotSessionType>;
}) {
  if (aiBotsListLoading || aiBotsSessionLoading) {
    return <ActiveChatLoading />;
  }

  return aiBotsListError && aiBotsSessionError ? (
    <></>
  ) : (
    <ActiveChat sessionsData={sessionsData} />
  );
}

export function AiBotsMainPage() {
  const aiBotsListQuery = useAiBotsListQuery();
  const aiBotsSessionsQuery = useSessionsListQuery();

  useEffect(() => {
    if (!isEmpty(aiBotsListQuery.data)) {
      MainPageAnalytics.botsListView();
    }
  }, [aiBotsListQuery.data]);

  const isAiBotsListError = isListError({
    isError: aiBotsListQuery.isError,
    data: aiBotsListQuery.data,
  });

  const isAiSessionsError = isSessionsError({
    isError: aiBotsSessionsQuery.isError,
    data: aiBotsSessionsQuery.data,
  });

  const isFullPageErrorVisible =
    !aiBotsListQuery.isLoading &&
    !aiBotsSessionsQuery.isLoading &&
    isAiBotsListError &&
    isAiSessionsError;

  function onRetry() {
    aiBotsListQuery.refetch();
    aiBotsSessionsQuery.refetch();
  }

  return (
    <UiBaseLayout>
      {isFullPageErrorVisible ? (
        <FullPageError onRetry={onRetry} />
      ) : (
        <>
          <ActiveChatContainer
            aiBotsListLoading={aiBotsListQuery.isLoading}
            aiBotsListError={isAiBotsListError}
            aiBotsSessionLoading={aiBotsSessionsQuery.isLoading}
            aiBotsSessionError={isAiSessionsError}
            sessionsData={aiBotsSessionsQuery.data}
          />

          <AiBotsListContainer isLoading={aiBotsListQuery.isLoading} />
        </>
      )}
    </UiBaseLayout>
  );
}
