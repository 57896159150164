import { useTranslation } from 'react-i18next';

import { AiBot } from '../../../entities/ai-bot';
import { useAiBotsListQuery } from '../../../feature/ai-bots-list';
import { AiBotsListContainer } from './AiBotsListContainer';
import './assets/ai-bots-list.css';

export function AiBotsList({
  withSearchForm,
  onCategoryButtonClicked,
  onClickToBot,
}: {
  withSearchForm?: boolean;
  onCategoryButtonClicked?: () => void;
  onClickToBot: (bot: AiBot) => void;
}) {
  const { t } = useTranslation();
  const { data } = useAiBotsListQuery();

  if (!data) {
    //TODO: log to bug tracker ERR_AI_BOTS_LIST_RENDER_WITHOUT_DATA
    return <></>;
  }

  return (
    <section className="ai-bots-list">
      <h1 className="ai-bots-list__title">{t('ai-bots.explore-bots-title')}</h1>
      <AiBotsListContainer
        data={data}
        withSearchForm={withSearchForm}
        onCategoryButtonClicked={onCategoryButtonClicked}
        onClickToBot={onClickToBot}
      />
    </section>
  );
}
