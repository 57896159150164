import { motion } from 'framer-motion';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { usePersonalProfileQuery } from '../../../../../entities/personal-profile';
import { analyticsService } from '../../../../../services/analytics.service';
import useAiPathwayExperiment from '../../../../../shared/hooks/use-ai-pathway-experiment';
import { getQueryParamsFromURI } from '../../../../../utils/QueryParams/QueryParams';
import { UiPrimaryButton } from '../../../../shared/ui';
import { getAllowedArrow } from '../../../../upsell/upsell-cascade/lib';
import { OnboardingV2Analytics } from '../../../shared/analytics';
import { useLottieAiExperiment } from '../../../shared/hooks/use-lottie-ai-experiment';
import { getAnalyticsState } from '../../../shared/lib';
import './assets/personal-plan-page.css';

export function OnboardingV2PersonalPlanPage() {
  const navigation = useHistory();

  const queryParams = getQueryParamsFromURI();

  const userPersonalProfile = usePersonalProfileQuery(true);
  const arrow = userPersonalProfile?.data?.personalization_criteria?.arrow?.[0];

  const lottieOnboardingExperiment = useLottieAiExperiment();

  const userArrow = getAllowedArrow(arrow);
  const fromQuery = queryParams?.from;

  const { remoteWorkAnalyticsValue, influencerAnalyticsValue } =
    getAnalyticsState({
      query: userArrow,
      remoteWorExperiment: 'test',
      influencerExperiment: 'test',
      fromQuery: fromQuery,
    });

  const { value: aiPathwayExperiment } = useAiPathwayExperiment({
    isLoading: false,
  });

  function onNext() {
    OnboardingV2Analytics.onIamReady({
      remote_work: remoteWorkAnalyticsValue,
      influencer: influencerAnalyticsValue,
      pathway_test: aiPathwayExperiment,
      lottie_test: lottieOnboardingExperiment,
    });

    const arrowRedirect = userArrow !== 'main' ? `?arrow=${userArrow}` : '';

    if (aiPathwayExperiment === 'test') {
      const aiToolPath = `/onboarding/v2/ai-tool${arrowRedirect}`;
      return navigation.push(aiToolPath);
    }
    const startOnboardingPath = `/onboarding/v2/start-onboarding${arrowRedirect}`;
    navigation.push(startOnboardingPath);
  }

  const { t } = useTranslation(undefined, {
    keyPrefix: `onboarding-redesign.${userArrow}.welcome-page`,
  });

  useEffect(() => {
    analyticsService.setAmplitudeUserProperties({
      exp_remote_work: remoteWorkAnalyticsValue,
      exp_influencer: influencerAnalyticsValue,
    });
    OnboardingV2Analytics.personalPlanPageView({
      remote_work: remoteWorkAnalyticsValue,
      influencer: influencerAnalyticsValue,
      pathway_test: aiPathwayExperiment,
      lottie_test: lottieOnboardingExperiment,
    });
  }, [remoteWorkAnalyticsValue, influencerAnalyticsValue, aiPathwayExperiment]);

  return (
    <motion.section
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="personal-plan-page min-vh-full"
    >
      <div className="personal-plan-page__content">
        <div className="personal-plan-page__header">
          <h1>{t('title')}</h1>
          <p>{t('text')}</p>
        </div>
        <div>
          <div className="personal-plan-page__body">
            <div className="personal-plan-page_line" />
            <div className="personal-plan-page__content-header">
              <h2>{t('content.title')}</h2>
              <div>{t('content.module-count')}</div>
            </div>
          </div>
          <div className="personal-plan-page__image-container">
            <img
              className="personal-plan-page__image"
              src={t('content.image') as string}
              role="presentation"
              alt=""
            />
          </div>
        </div>
        <div className="personal-plan-page__gradient"></div>
      </div>
      <div className="personal-plan-page__actions">
        <UiPrimaryButton onClick={onNext}>{t('action-button')}</UiPrimaryButton>
      </div>
    </motion.section>
  );
}
