import { GuideType } from '../../../guides/entities/guide';

export function filterGuides(
  guides: GuideType[] | undefined,
  progress: Record<string, number> | undefined,
  arrow: string | undefined
): GuideType[] | undefined {
  return guides
    ?.filter(
      guide =>
        guide.tags.some(tag => tag.toLowerCase() === arrow) &&
        (progress?.[guide.id] ?? 0) < 1
    )
    .sort((a, b) => a.ordering - b.ordering);
}
