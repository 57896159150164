import type { AxiosResponse } from 'axios';

import { api } from '../../../../../services/http.service';
import type { AiBot } from '../../../entities/ai-bot';

export interface AiBotsListType {
  defaults: Array<AiBot>;
  custom: Array<AiBot>;
}

export const AiBotsListAPI = {
  getAiBots(): Promise<AxiosResponse<AiBotsListType>> {
    return api.get('/ai-chatbots');
  },
};
