import { useAutoAnimate } from '@formkit/auto-animate/react';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { userApi } from '../../../../../api/userApi';
import {
  localOnboardState,
  userQuery,
} from '../../../../../context/atoms/User';
import SurveyImage from '../../../../../images/guides/survey.svg';
import SurveyIcon1 from '../../../../../images/guides/survey/1.png';
import SurveyIcon2 from '../../../../../images/guides/survey/2.png';
import SurveyIcon3 from '../../../../../images/guides/survey/3.png';
import SurveyIcon4 from '../../../../../images/guides/survey/4.png';
import SurveyIcon5 from '../../../../../images/guides/survey/5.png';
import useAiPathwayExperiment from '../../../../../shared/hooks/use-ai-pathway-experiment';
import { UiPrimaryButton } from '../../../../shared/ui';
import { OnboardingV2Analytics } from '../../../shared/analytics';
import { useLottieAiExperiment } from '../../../shared/hooks/use-lottie-ai-experiment';
import './assets/survey-page.css';

const ICONS = [SurveyIcon1, SurveyIcon2, SurveyIcon3, SurveyIcon4, SurveyIcon5];

export function OnboardingV2SurveyPage() {
  const { t } = useTranslation();
  const user = useRecoilValue(userQuery);
  const setLocalOnboard = useSetRecoilState(localOnboardState);

  const lottieOnboardingExperiment = useLottieAiExperiment();

  const { value: aiPathwayExperiment } = useAiPathwayExperiment({
    isLoading: false,
  });

  const navigation = useHistory();
  const [contentRef] = useAutoAnimate();
  const [clickedAnswerIndex, setClickedAnswerIndex] = useState(-1);

  useEffect(() => {
    OnboardingV2Analytics.surveyPageView({
      pathway_test: aiPathwayExperiment,
      lottie_test: lottieOnboardingExperiment,
    });
  }, [aiPathwayExperiment]);

  function onRateClick(idx: number) {
    setClickedAnswerIndex(idx);
    OnboardingV2Analytics.onSurveyRate({
      rating: idx + 1,
      pathway_test: aiPathwayExperiment,
      lottie_test: lottieOnboardingExperiment,
    });
  }

  function onClose() {
    OnboardingV2Analytics.onSurveyContinue({
      rating: clickedAnswerIndex + 1,
      pathway_test: aiPathwayExperiment,
      lottie_test: lottieOnboardingExperiment,
    });
    userApi.setOnboarded(user?.user_id as number);
    setLocalOnboard(true);
    navigation.replace(`/`);
  }

  function onContinue() {
    OnboardingV2Analytics.onSurveyContinue({
      rating: clickedAnswerIndex + 1,
      pathway_test: aiPathwayExperiment,
      lottie_test: lottieOnboardingExperiment,
    });
    userApi.setOnboarded(user?.user_id as number);
    setLocalOnboard(true);
    navigation.replace(`/`);
  }

  return (
    <motion.main
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="survey-page min-vh-full"
    >
      <div className="survey-page__header">
        <button
          type="button"
          className="survey-page__close-button"
          onClick={onClose}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="none"
          >
            <path
              fill="#565B66"
              d="M9.293 9.293a1 1 0 0 1 1.414 0L16 14.586l5.293-5.293a1 1 0 1 1 1.414 1.414L17.414 16l5.293 5.293a1 1 0 0 1-1.414 1.414L16 17.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L14.586 16l-5.293-5.293a1 1 0 0 1 0-1.414Z"
            />
          </svg>
        </button>
      </div>

      <div ref={contentRef} className="survey-page__content">
        <img
          width="112"
          height="112"
          className="survey-page__image"
          src={SurveyImage}
          alt=""
          role="presentation"
        />
        <h1 className="survey-page__title">{t('guides.survey.title')}</h1>
        <div className="survey-page__options">
          <div className="survey-page__options-container">
            {ICONS.map((icon, idx) => (
              <button
                key={idx}
                type="button"
                className={classNames(
                  'survey-page__option',
                  clickedAnswerIndex === idx
                    ? 'survey-page__option--active'
                    : ''
                )}
                onClick={() => onRateClick(idx)}
              >
                <img
                  width="30"
                  height="30"
                  className="survey-page__option-icon"
                  src={icon}
                  alt=""
                />
              </button>
            ))}
          </div>
          <div className="survey-page__options-description">
            <span className="survey-page__options-bad">
              {t('guides.survey.bad')}
            </span>
            <span className="survey-page__options-good">
              {t('guides.survey.good')}
            </span>
          </div>
        </div>
      </div>

      <div className="survey-page__actions">
        <UiPrimaryButton onClick={onContinue}>
          {t('guides.continue-button')}
        </UiPrimaryButton>
      </div>
    </motion.main>
  );
}
