import { Fragment, useRef } from 'react';

import { CustomerMessage } from '../../../../ai-bots/entities/message';
import { GuideLessonDetailsFillTheGapType } from '../../../entities/guide-lesson/types';
import { FillInImage } from '../../fill-in-image';
import { FillInPlaceholder } from '../../fill-in-placeholder';
import { TypingAIMessage } from '../../typing-ai-message';
import { FillInState } from '../lib/types';
import {
  generateCustomerMessage,
  getFillInParts,
  splitTextIntoParagraphs,
} from '../utils';

export function FillTheGapModalContent({
  task,
  isChecked,
  isCorrectAnswer,
  onPlaceholderClick,
  fillInState,
  isOnboardingArrow,
  scrollToBottom,
}: {
  task: GuideLessonDetailsFillTheGapType;
  isChecked: boolean;
  isCorrectAnswer: boolean;
  onPlaceholderClick: (placeholder: string) => void;
  fillInState: FillInState;
  isOnboardingArrow?: boolean;
  scrollToBottom?: () => void;
}) {
  const initFillInParts = getFillInParts(task);

  const taskAnswerImages = task.content[0]?.answer_images;
  const taskAnswer = task.content[0]?.answer;

  const isWithResponse = taskAnswer || taskAnswerImages;

  function onHeightChange() {
    scrollToBottom?.();
  }

  if (!isOnboardingArrow && isChecked && isCorrectAnswer && isWithResponse) {
    if (taskAnswerImages && taskAnswerImages.length > 0) {
      return (
        <div className="guide-fill-the-gap-modal__body">
          <CustomerMessage
            key={'customer_message'}
            text={generateCustomerMessage(initFillInParts, fillInState.answers)}
          />
          <FillInImage
            avatar={task.content[0]?.tool?.icon}
            images={taskAnswerImages}
            onHeightChange={onHeightChange}
          />
        </div>
      );
    }

    if (taskAnswer) {
      return (
        <div className="guide-fill-the-gap-modal__body">
          <div>
            <CustomerMessage
              key={'customer_message'}
              text={generateCustomerMessage(
                initFillInParts,
                fillInState.answers
              )}
            />
            <TypingAIMessage
              text={taskAnswer}
              onHeightChange={onHeightChange}
            />
          </div>
        </div>
      );
    }
  }

  return (
    <div className="guide-fill-the-gap-modal__body">
      <div className="guide-fill-the-gap-modal__fill-in-words">
        {initFillInParts?.map((item, index) => {
          const paragraphs = item.text
            ? splitTextIntoParagraphs(item.text)
            : [''];
          return (
            <Fragment key={index}>
              {paragraphs?.map(
                (paragraph, i) => paragraph && <p key={i}>{paragraph}</p>
              )}
              {item.placeholder && (
                <FillInPlaceholder
                  onClick={() =>
                    item.placeholder && onPlaceholderClick(item.placeholder)
                  }
                  placeholder={item.placeholder}
                  isActive={item.placeholder === fillInState.activePlaceholder}
                  value={
                    fillInState.answers.find(
                      ({ placeholder }) => placeholder === item.placeholder
                    )?.answer || null
                  }
                />
              )}
            </Fragment>
          );
        })}
      </div>
    </div>
  );
}
