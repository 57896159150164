import { useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import useAiPathwayExperiment from '../../../../../shared/hooks/use-ai-pathway-experiment';
import { getQueryParamsFromURI } from '../../../../../utils/QueryParams/QueryParams';
import '../../../../ai-bots/shared/ui/base-layout/ui/ui-base-layout.css';
import {
  GuideChunk,
  GuideLessonDetailsContentType,
  GuideLessonDetailsTaskType,
  GuideLessonDetailsType,
  useGuideLessonsQuery,
} from '../../../entities/guide-lesson';
import { useSaveLessonProgressMutation } from '../../../entities/guide-lesson-progress';
import { useGuideByIdProgressQuery } from '../../../entities/guide-progress';
import { GuidesAnalytics } from '../../../shared/analytics';
import { GuideLessonWidget } from '../../../widgets/guide-lesson-widget';

export function GuideLessonPage() {
  const navigation = useHistory();
  const {
    guideId,
    unitId,
    lessonId,
  }: {
    guideId: string;
    unitId: string;
    lessonId: string;
  } = useParams();

  const queryParams = getQueryParamsFromURI();
  const pathway = queryParams?.pathway;

  const { value: aiPathwayExperiment } = useAiPathwayExperiment({
    isLoading: false,
  });

  const guideLessonsQuery = useGuideLessonsQuery({
    guideId,
    unitId,
    lessonId,
  });

  const guideByIdProgressQuery = useGuideByIdProgressQuery({
    id: guideId,
  });
  const saveLessonProgressMutation = useSaveLessonProgressMutation();
  const lessonProgress = useMemo(() => {
    if (guideByIdProgressQuery.data) {
      return guideByIdProgressQuery.data.units[unitId].lessons[lessonId].status;
    }
  }, [guideByIdProgressQuery.data]);

  function onOpenTask(props: {
    place: GuideLessonDetailsType['type'];
    pageId: GuideLessonDetailsContentType['id'];
    chunkId: GuideLessonDetailsTaskType['id'];
  }) {
    GuidesAnalytics.onOpenTask({
      place: props.place,
      guideId,
      unitId,
      lessonId,
      pageId: props.pageId,
      chunkId: props.chunkId,
    });
  }

  function onFinishLesson() {
    if (lessonProgress !== 'completed') {
      return saveLessonProgressMutation
        .mutateAsync({
          guideId,
          unitId,
          lessonId,
        })
        .finally(() => {
          navigation.replace(
            `/guides/${guideId}/${unitId}/${lessonId}/complete`
          );
        });
    }

    navigation.replace(`/guides/${guideId}/${unitId}/${lessonId}/complete`);
  }

  function onLessonChunkView(props: {
    place: GuideLessonDetailsType['type'];
    pageId: GuideLessonDetailsContentType['id'];
    chunkId: GuideChunk['id'];
  }) {
    GuidesAnalytics.lessonChunkView({
      place: props.place,
      guideId,
      unitId,
      lessonId,
      pageId: props.pageId,
      chunkId: props.chunkId,
    });
  }

  function onSkipPractice(props: {
    place: GuideLessonDetailsType['type'];
    pageId: GuideLessonDetailsContentType['id'];
    chunkId: GuideChunk['id'];
  }) {
    GuidesAnalytics.onSkipTask({
      place: props.place,
      guideId,
      unitId,
      lessonId,
      pageId: props.pageId,
      chunkId: props.chunkId,
    });
  }

  function onSelectQuizOption(props: {
    place: GuideLessonDetailsType['type'];
    pageId: GuideLessonDetailsContentType['id'];
    chunkId: GuideChunk['id'];
  }) {
    GuidesAnalytics.onQuizAnswerSelect({
      place: props.place,
      guideId,
      unitId,
      lessonId,
      pageId: props.pageId,
      chunkId: props.chunkId,
    });
  }

  function onTriggerQuizHint(props: {
    place: GuideLessonDetailsType['type'];
    pageId: GuideLessonDetailsContentType['id'];
    chunkId: GuideChunk['id'];
  }) {
    GuidesAnalytics.onQuizHintClick({
      place: props.place,
      guideId,
      unitId,
      lessonId,
      pageId: props.pageId,
      chunkId: props.chunkId,
    });
  }

  function onQuizSubmit(props: {
    place: GuideLessonDetailsType['type'];
    pageId: GuideLessonDetailsContentType['id'];
    chunkId: GuideChunk['id'];
    isCorrect: boolean;
  }) {
    GuidesAnalytics.onQuizSubmit({
      place: props.place,
      guideId,
      unitId,
      lessonId,
      pageId: props.pageId,
      chunkId: props.chunkId,
      isCorrect: props.isCorrect,
    });
  }

  function onTaskExerciseToastTrigger(props: {
    place: GuideLessonDetailsType['type'];
    pageId: GuideLessonDetailsContentType['id'];
    chunkId: GuideChunk['id'];
    isVisible: boolean;
  }) {
    GuidesAnalytics.onTriggerToast({
      place: props.place,
      guideId,
      unitId,
      lessonId,
      pageId: props.pageId,
      chunkId: props.chunkId,
      isVisible: props.isVisible,
    });
  }

  function onTaskSubmitMessage(props: {
    place: GuideLessonDetailsType['type'];
    pageId: GuideLessonDetailsContentType['id'];
    chunkId: GuideChunk['id'];
    isRetrySubmission: boolean;
  }) {
    GuidesAnalytics.onSubmitMessage({
      place: props.place,
      guideId,
      unitId,
      lessonId,
      pageId: props.pageId,
      chunkId: props.chunkId,
      isRetrySubmission: props.isRetrySubmission,
    });
  }

  function onTaskReceivesAnswer(props: {
    place: GuideLessonDetailsType['type'];
    pageId: GuideLessonDetailsContentType['id'];
    chunkId: GuideChunk['id'];
    isSuccess: boolean;
    isSubmissionCorrect: boolean;
  }) {
    GuidesAnalytics.onReceivesAnswer({
      place: props.place,
      guideId,
      unitId,
      lessonId,
      pageId: props.pageId,
      chunkId: props.chunkId,
      isSuccess: props.isSuccess,
      isSubmissionCorrect: props.isSubmissionCorrect,
    });
  }

  function onGoBack() {
    if (aiPathwayExperiment === 'test' && pathway === 'test') {
      return navigation.replace(`/guide-pathway/${guideId}`);
    }
    return navigation.replace(`/guides/${guideId}`);
  }

  return (
    <GuideLessonWidget
      guide={guideLessonsQuery.data}
      isError={guideLessonsQuery.isError}
      isLoading={guideLessonsQuery.isLoading}
      isFinishLessonLoading={saveLessonProgressMutation.isLoading}
      lessonProgress={lessonProgress}
      events={{
        onFinishLesson,
        onRefetch: guideLessonsQuery.refetch,
        onOpenTask,
        onLessonChunkView,
        onSkipPractice,
        onSelectQuizOption,
        onTriggerQuizHint,
        onQuizSubmit,
        onTaskReceivesAnswer,
        onTaskExerciseToastTrigger,
        onTaskSubmitMessage,
        onGoBack: () => onGoBack(),
      }}
    />
  );
}
