import { filter, isEmpty, size } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import type { AiBot } from '../../../entities/ai-bot';
import { AiBotCard } from '../../../entities/ai-bot-card';
import { AiBotsListType } from '../../../feature/ai-bots-list';
import { EmptyResults } from '../../../feature/empty-results';
import { SearchForm } from '../../../feature/search-form';
import { getSortedListByCategoryId, getUniqCategories } from '../lib';
import { CategoryButton } from './CategoryButton';
import './assets/ai-bots-list.css';

export function AiBotsListContainer({
  data,
  withSearchForm,
  onCategoryButtonClicked,
  onClickToBot,
  onSearchFormClick,
  onSearchFormSubmit,
}: {
  data: AiBotsListType;
  withSearchForm?: boolean;
  onCategoryButtonClicked?: () => void;
  onClickToBot?: (bot: AiBot) => void;
  onSearchFormClick?: () => void;
  onSearchFormSubmit?: (count: number) => void;
}) {
  const { t } = useTranslation();
  const navigation = useHistory();
  const categories = getUniqCategories(data.defaults);
  const aiBotsList = data.defaults;

  const [currentCategoryId, setCurrentCategoryId] = useState(categories[0].id);
  const [searchQuery, setSearchQuery] = useState('');

  const sortedListByCategoryId = getSortedListByCategoryId({
    list: aiBotsList,
    id: currentCategoryId,
  });

  const listBySearchQuery = useMemo(() => {
    return filter(sortedListByCategoryId, item =>
      item.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [sortedListByCategoryId, searchQuery]);

  useEffect(() => {
    if (searchQuery) {
      onSearchFormSubmit?.(size(listBySearchQuery));
    }
  }, [searchQuery, listBySearchQuery]);

  function onSearch(query: string) {
    setSearchQuery(query);
  }

  function onSetCurrentCategoryId(id: string) {
    setCurrentCategoryId(id);
    onCategoryButtonClicked?.();
  }

  function goToChatPage(aiBot: AiBot) {
    onClickToBot?.(aiBot);
    navigation.push(`/ai-bots/new-chat/${aiBot.id}`, {
      from: navigation.location.pathname,
    });
  }

  const searchPlaceholder = t('ai-bots.search.bots-placeholder');

  return (
    <section className="ai-bots-list__container">
      <div className="ai-bots-list__category-buttons">
        {categories.map(category => (
          <CategoryButton
            key={category.id}
            isActive={category.id === currentCategoryId}
            category={category}
            onClick={onSetCurrentCategoryId}
          />
        ))}
      </div>
      {withSearchForm && (
        <div className="ai-bots-list__search-form">
          <SearchForm
            placeholder={searchPlaceholder}
            onClick={() => onSearchFormClick?.()}
            onSearch={text => onSearch(text)}
          />
        </div>
      )}
      {isEmpty(listBySearchQuery) ? (
        <div className="ai-bots-list-empty">
          <EmptyResults />
        </div>
      ) : (
        <div className="ai-bots-list__content">
          {listBySearchQuery.map(aiBot => (
            <AiBotCard
              key={aiBot.id}
              data={aiBot}
              onClick={() => goToChatPage(aiBot)}
            />
          ))}
        </div>
      )}
    </section>
  );
}
