import { UiLoadingSkeleton } from '../../../shared/ui';

export function AiBotCardLoading() {
  return (
    <UiLoadingSkeleton
      style={{
        height: '80px',
      }}
    />
  );
}
