import { isEqual } from 'lodash-es';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Joyride, { ACTIONS, LIFECYCLE, Step } from 'react-joyride';

import { OnboardingChunk } from '../../../../../entities/onboarding-content/types';
import { usePersonalProfileQuery } from '../../../../../entities/personal-profile';
import useAiPathwayExperiment from '../../../../../shared/hooks/use-ai-pathway-experiment';
import { getQueryParamsFromURI } from '../../../../../utils/QueryParams/QueryParams';
import { GuideLessonDetailsFillTheGapType } from '../../../../guides/entities/guide-lesson/types';
import { useFillInState } from '../../../../guides/features/fill-the-gap-modal/hooks/use-fillin-state';
import { FillInActionType } from '../../../../guides/features/fill-the-gap-modal/lib/types';
import { FillTheGapModalActions } from '../../../../guides/features/fill-the-gap-modal/ui/FillTheGapModalActions';
import { FillTheGapModalContent } from '../../../../guides/features/fill-the-gap-modal/ui/FillTheGapModalContent';
import { FillTheGapModalHeader } from '../../../../guides/features/fill-the-gap-modal/ui/FillTheGapModalHeader';
import { getFillInParts } from '../../../../guides/features/fill-the-gap-modal/utils';
import {
  GuideErrorFeedback,
  GuideSuccessFeedback,
} from '../../../../guides/features/guide-feedback';
import { useLockScreen } from '../../../../guides/features/task-modal/model';
import { OnboardingV2Analytics } from '../../analytics';
import { useLottieAiExperiment } from '../../hooks/use-lottie-ai-experiment';
import { LessonLayoutProgress } from '../../lesson-content/ui/LessonLayoutProgress';
import { allowedArrows } from '../../lib';
import { joyrideStyles } from '../lib/consts';
import './../../../../guides/features/fill-the-gap-modal/ui/fill-the-gap-modal.css';

let toastTimeout: ReturnType<typeof setTimeout>;

export function OnboardingV2TaskPage({
  taskContent,
  onNext,
  moveProgress,
  onClose,
  progress,
}: {
  taskContent: OnboardingChunk[];
  onNext: () => void;
  moveProgress: () => void;
  onClose: () => void;
  progress: number;
}) {
  const firstCorrectAnswerButtonRef = useRef<HTMLButtonElement>(null);
  const [isTourActive, setIsTourActive] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isCorrectAnswer, setIsCorrectAnswer] = useState(false);
  const taskGaps = taskContent[0].content[0].gaps;

  const queryParams = getQueryParamsFromURI();
  const userArrow = queryParams?.arrow;

  const isOnboardingArrow = allowedArrows.includes(userArrow) ? true : false;

  const { value: aiPathwayExperiment } = useAiPathwayExperiment({
    isLoading: false,
  });

  const lottieOnboardingExperiment = useLottieAiExperiment();

  const { t } = useTranslation();

  const steps: Step[] = [
    {
      target: '#first_correct_answer',
      content: t('onboarding-redesign.main.click-here'),
      placement: 'top',
      disableBeacon: true,
    },
  ];

  const chatListRef = useRef<HTMLDivElement>(null);

  useLockScreen(chatListRef);

  const initFillInParts = getFillInParts(
    taskContent[0] as GuideLessonDetailsFillTheGapType
  );

  const { fillInState, dispatch } = useFillInState({
    fillInParts: initFillInParts,
    answers: [],
    activePlaceholder:
      initFillInParts?.find(item => item.placeholder)?.placeholder || null,
  });

  const onTaskCheck = () => {
    const fillInAnswers = fillInState.answers.map(item => item.answer);
    const isCorrect = isEqual(fillInAnswers, taskGaps);
    setIsCorrectAnswer(isCorrect);
    setIsChecked(true);
    if (isCorrect) {
      moveProgress();
    }

    OnboardingV2Analytics.onTaskCheck({
      is_correct: isCorrect,
      pathway_test: aiPathwayExperiment,
      lottie_test: lottieOnboardingExperiment,
    });
  };
  const onOptionClick = (answer: string) => {
    OnboardingV2Analytics.onTaskBubbleClick({
      pathway_test: aiPathwayExperiment,
      lottie_test: lottieOnboardingExperiment,
    });
    dispatch({ type: FillInActionType.ADD_USER_ANSWER, payload: answer });
  };

  const onBackspaceClick = () => {
    dispatch({
      type: FillInActionType.DESELECT_LAST_ANSWER,
    });
  };

  const onPlaceholderClick = (placeholder: string) => {
    dispatch({
      type: FillInActionType.DESELECT_USER_ANSWER,
      payload: placeholder,
    });
  };
  const scrollToBottom = () => {
    if (chatListRef.current) {
      setTimeout(() =>
        chatListRef.current!.scrollTo({
          top: chatListRef.current!.scrollHeight,
          behavior: 'smooth',
        })
      );
    }
  };

  const onRetry = () => {
    dispatch({
      type: FillInActionType.RESET,
    });
    setIsChecked(false);
  };

  useEffect(() => {
    scrollToBottom();
  }, [fillInState]);

  const handleJoyrideCallback = (data: any) => {
    const { action, lifecycle } = data;

    if (action === ACTIONS.CLOSE && lifecycle === LIFECYCLE.COMPLETE) {
      firstCorrectAnswerButtonRef.current?.click();
    }
  };

  useEffect(() => {
    OnboardingV2Analytics.taskPageView({
      pathway_test: aiPathwayExperiment,
      lottie_test: lottieOnboardingExperiment,
    });
    return () => {
      clearTimeout(toastTimeout);
    };
  }, [aiPathwayExperiment]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsTourActive(true);
    }, 1500);

    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <>
      <Joyride
        floaterProps={{
          disableAnimation: true,
        }}
        disableScrolling
        steps={steps}
        spotlightPadding={0}
        hideCloseButton
        hideBackButton
        styles={joyrideStyles}
        run={isTourActive}
        callback={handleJoyrideCallback}
      />
      <main className="vh-full guide-fill-the-gap-modal">
        <section className="guide-fill-the-gap-modal__content">
          <div
            ref={chatListRef}
            className="guide-fill-the-gap-modal__scrollable-content"
          >
            <LessonLayoutProgress progress={progress} onClose={onClose} />
            <FillTheGapModalHeader
              task={taskContent[0] as GuideLessonDetailsFillTheGapType}
              onClose={() => {}}
              disableNavigation
              isOnboardingArrow={isOnboardingArrow}
            />
            <FillTheGapModalContent
              task={taskContent[0] as GuideLessonDetailsFillTheGapType}
              isChecked={isChecked}
              isCorrectAnswer={isCorrectAnswer}
              onPlaceholderClick={onPlaceholderClick}
              fillInState={fillInState}
              isOnboardingArrow={isOnboardingArrow}
              scrollToBottom={scrollToBottom}
            />
          </div>
          <FillTheGapModalActions
            buttonRef={firstCorrectAnswerButtonRef}
            taskGaps={taskGaps as string[]}
            fillInState={fillInState}
            isChecked={isChecked}
            onTaskCheck={onTaskCheck}
            onOptionClick={onOptionClick}
            onBackspaceClick={onBackspaceClick}
            task={taskContent as GuideLessonDetailsFillTheGapType[]}
          />

          {!isCorrectAnswer && isChecked && (
            <GuideErrorFeedback
              feedback={`Correct answer: ${(taskGaps as string[]).join(', ')}`}
              onRetry={onRetry}
            />
          )}
          {isCorrectAnswer && isChecked && (
            <GuideSuccessFeedback
              feedback={"You're right on track with your approach"}
              onContinue={onNext}
            />
          )}
        </section>
      </main>
    </>
  );
}
