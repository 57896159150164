import { useQuery } from '@tanstack/react-query';

import i18n from '../../../i18n';
import { OnboardingContentAPI } from '../api';

export function useOnboardingContent(arrow: string) {
  return useQuery({
    queryKey: ['onboarding-content', arrow],
    queryFn: () =>
      OnboardingContentAPI.getOnboardingContent(i18n.language, arrow).then(
        res => res.data
      ),
    staleTime: Infinity,
  });
}
