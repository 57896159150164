import { AiBotCardLoading } from '../../../entities/ai-bot-card';
import { UiLoadingSkeleton } from '../../../shared/ui';
import './assets/ai-bots-list.css';

export function AiBotsListLoading() {
  return (
    <div className="ai-bots_list">
      <div className="ai-bots-list__title">
        <UiLoadingSkeleton
          style={{
            width: '129px',
            height: '24px',
          }}
        />
      </div>

      <div className="ai-bots-list__category-buttons ai-bots-list__category-buttons-loading">
        {Array(5)
          .fill(0)
          .map((_, i) => (
            <UiLoadingSkeleton
              key={i}
              style={{
                minWidth: '92px',
                height: '37px',
                borderRadius: '64px',
              }}
            />
          ))}
      </div>

      <div className="ai-bots-list__content">
        {Array(3)
          .fill(0)
          .map((_, i) => (
            <AiBotCardLoading key={i} />
          ))}
      </div>
    </div>
  );
}
