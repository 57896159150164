import { useQuery, useQueryClient } from '@tanstack/react-query';

import { useCurrency } from './currency';

const CURRENCY_QUERY_KEY = ['currency'];

export function useCurrencySignQuery() {
  const { getCurrency } = useCurrency();

  return useQuery({
    queryKey: CURRENCY_QUERY_KEY,
    queryFn: () => getCurrency(),
    staleTime: Infinity,
  });
}

export function usePrefetchCurrencySignQuery() {
  const { getCurrency } = useCurrency();
  const queryClient = useQueryClient();

  function prefetchCurrencyQuery() {
    return queryClient.prefetchQuery({
      queryKey: CURRENCY_QUERY_KEY,
      queryFn: () => getCurrency(),
    });
  }

  return {
    prefetchCurrencyQuery,
  };
}
