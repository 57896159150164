import { useTranslation } from 'react-i18next';

import { GuideType } from '../../../../guides/entities/guide';
import './assets/guides-list-arrow.css';

export function GuidesListArrow({
  guides,
  onGuideClick,
  progressList,
}: {
  guides: Array<GuideType>;
  onGuideClick: (guide: GuideType, isPathway: boolean) => void;
  progressList: Record<GuideType['id'], number>;
}) {
  const { t } = useTranslation();

  function getProgress(guide: GuideType) {
    return progressList[guide.id] * 100;
  }

  return (
    <section className="guides-list-arrow">
      <h1 className="guides-list-arrow__title">
        {t('guides-v2.other-guides-title')}
      </h1>

      <div className="guides-list-arrow__list">
        {guides.map(guide => (
          <button
            key={guide.id}
            className="guides-list-arrow__item"
            onClick={() => onGuideClick(guide, true)}
          >
            <div className="guides-list-arrow__item-image-container">
              <img
                className="guides-list-arrow__item-image"
                width="72"
                height="72"
                src={guide.image}
                role="presentation"
                alt=""
              />
            </div>

            <strong className="guides-list-arrow__item-title">
              {guide.name}
            </strong>
            <div className="guides-list-arrow__item-duration">
              <p>
                {t('guides-v2.lessons')} {guide.lessons_count}
              </p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="5"
                height="5"
                fill="none"
              >
                <circle cx="2.5" cy="2.5" r="2" fill="#565B66" />
              </svg>
              <p>
                {guide.units_count} {t('guides-v2.levels')}
              </p>
            </div>
            <div className="guides-list-arrow__progress">
              <div
                className="guides-list-arrow__progress-line"
                style={{ width: `${getProgress(guide)}%` }}
              ></div>
            </div>
          </button>
        ))}
      </div>
    </section>
  );
}
