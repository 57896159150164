import Bugsnag from '@bugsnag/js';
import i18next from 'i18next';
import { map } from 'lodash-es';

import { UpsellCascadeType } from '../api';
import {
  BUNDLE_OFFER_IMAGE,
  BUSINESS_ESSENTIALS_OFFER_IMAGE,
  BUSINESS_MASTERY_BUNDLE_IMAGE,
  BUSINESS_PROCESSES_WITH_AI,
  PRODUCTIVITY_BOOST_GUIDE_IMAGE,
  PRODUCTIVITY_BOOST_OFFER_IMAGE,
} from '../ui/assets/offer-images';

const BUNDLE_OFFER = 'bundle';
const PRODUCTIVITY_BOOST_OFFER = 'upsell_productivity_boost';
const BUSINESS_ESSENTIALS_OFFER = 'upsell_business_essentials';

function getUpsellImage(type: string, forRedesign: boolean = false) {
  switch (type) {
    case BUNDLE_OFFER:
      return forRedesign ? BUSINESS_MASTERY_BUNDLE_IMAGE : BUNDLE_OFFER_IMAGE;
    case PRODUCTIVITY_BOOST_OFFER:
      return forRedesign
        ? PRODUCTIVITY_BOOST_GUIDE_IMAGE
        : PRODUCTIVITY_BOOST_OFFER_IMAGE;
    case BUSINESS_ESSENTIALS_OFFER:
      return forRedesign
        ? BUSINESS_PROCESSES_WITH_AI
        : BUSINESS_ESSENTIALS_OFFER_IMAGE;
    default:
      Bugsnag.notify(new Error('ERROR_UNSUPPORTED_OFFER_TYPE'), e => {
        e.addMetadata('metadata', {
          type,
        });
      });
      return '';
  }
}

function getUpsellTitle(type: string, forRedesign: boolean = false) {
  switch (type) {
    case BUNDLE_OFFER:
      return forRedesign
        ? i18next.t('upsell-cascade.redesign.offers.bundle-title')
        : i18next.t('upsell-cascade.offers.bundle-title');

    case PRODUCTIVITY_BOOST_OFFER:
      return forRedesign
        ? i18next.t(
            'upsell-cascade.redesign.offers.upsell-productivity-boost-title'
          )
        : i18next.t('upsell-cascade.offers.upsell-productivity-boost-title');

    case BUSINESS_ESSENTIALS_OFFER:
      return forRedesign
        ? i18next.t('upsell-cascade.redesign.offers.optimizing-business-title')
        : i18next.t('upsell-cascade.offers.upsell-business-essentials-title');

    default:
      Bugsnag.notify(new Error('ERROR_UNSUPPORTED_OFFER_TYPE'), e => {
        e.addMetadata('metadata', {
          type,
        });
      });
      return '';
  }
}

function getUpsellPricing(
  type: string,
  pricing: UpsellCascadeType['pricings'][0],
  currency: string
) {
  const discountPrice = (pricing.original_pricing.discount_price / 100).toFixed(
    2
  );
  const discountPercent = pricing.discount;
  const fullPrice = (pricing.original_pricing.full_price / 100).toFixed(2);
  const priceWithVat = (pricing.discount_price / 100).toFixed(2);

  return {
    discountPercent: i18next.t('upsell-cascade.discount-percent', {
      discountPercent,
    }),
    discountPrice: `${currency}${discountPrice}`,
    discountPriceText:
      type === BUNDLE_OFFER
        ? i18next.t('upsell-cascade.bundle-discount-price-text', {
            discountPrice: `${currency}${discountPrice}`,
          })
        : `${currency}${discountPrice}`,
    fullPrice: `${currency}${fullPrice}`,
    priceWithVat: `${currency}${priceWithVat}`,
  };
}

export interface MappedUpsellCascadeOffer {
  id: string;
  pricingId: string;
  image: string;
  title: string;
  currency: string;
  rawDiscountPrice: number;
  discountPrice: string;
  discountPriceText: string;
  discountPercent: string;
  fullPrice: string;
  vat: number;
  vatPrice: number;
  priceWithVat: string;
}

export function mapUpsellCascadeOffers({
  offers,
  currency,
  forRedesign = false,
}: {
  offers: Array<UpsellCascadeType>;
  currency: string;
  forRedesign?: boolean;
}) {
  return map(offers, offer => {
    const {
      discountPrice,
      discountPriceText,
      discountPercent,
      fullPrice,
      priceWithVat,
    } = getUpsellPricing(offer.product_type, offer.pricings[0], currency);

    return {
      id: offer.id,
      pricingId: offer.pricings[0].id,
      image: getUpsellImage(offer.product_type, forRedesign),
      title: getUpsellTitle(offer.product_type, forRedesign),
      rawDiscountPrice: offer.pricings[0].discount_price,
      vatPrice: offer.pricings[0].discount_price_vat_amount,
      currency: offer.currency,
      priceWithVat,
      discountPrice,
      discountPriceText,
      discountPercent,
      fullPrice,
      vat: offer.pricings[0].vat,
    };
  });
}

export function getArrow(arrow: string | undefined) {
  if (arrow === 'remote_work' || arrow === 'influencer') {
    return arrow;
  }
  return '';
}

export function getAllowedArrow(
  arrow: string | undefined,
  aiFallback: string = 'main'
) {
  if (arrow === 'remote_work' || arrow === 'influencer') {
    return arrow;
  }

  return aiFallback;
}
