import { AiBotMessageType } from '../types';
import { AiImageMessage } from './AiImageMessage';
import { AiMessage } from './AiMessage';
import { CustomerMessage } from './CustomerMessage';
import { MessageLoading } from './MessageLoading';

function MessageType({
  message,
  onOpenImage,
}: {
  message: AiBotMessageType;
  onOpenImage?: (url: string) => void;
}) {
  return message.type && message.type.includes('image') ? (
    <AiImageMessage url={message.response} onOpenImage={onOpenImage} />
  ) : (
    <AiMessage text={message.response} />
  );
}
export function ChatMessage({
  message,
  onOpenImage,
}: {
  message: AiBotMessageType;
  onOpenImage?: (url: string) => void;
}) {
  return (
    <div>
      <CustomerMessage
        key={`customer_message_${message.id}`}
        text={message.text}
      />
      {message.response ? (
        <MessageType
          key={`ai_message_${message.id}`}
          message={message}
          onOpenImage={onOpenImage}
        />
      ) : (
        <MessageLoading key={`message_loading_${message.id}`} />
      )}
    </div>
  );
}
