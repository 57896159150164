import { fill } from 'lodash-es';
import { useReducer } from 'react';

import { FillInAction, FillInActionType, FillInState } from '../lib/types';

function fillInReducer(state: FillInState, action: FillInAction): FillInState {
  let result = state;
  switch (action.type) {
    case FillInActionType.ADD_USER_ANSWER:
      result = {
        ...state,
        answers: [
          ...state.answers,
          {
            answer: action.payload,
            placeholder: state.activePlaceholder!,
          },
        ],
      };
      break;
    case FillInActionType.DESELECT_USER_ANSWER:
      result = {
        ...state,
        answers: state.answers.filter(
          ({ placeholder }) => placeholder !== action.payload
        ),
      };
      break;
    case FillInActionType.DESELECT_LAST_ANSWER:
      result = {
        ...state,
        answers: state.answers.slice(0, -1),
      };

      break;
    case FillInActionType.RESET:
      result = {
        ...state,
        answers: [],
        activePlaceholder:
          state.fillInParts?.find(item => item.placeholder)?.placeholder ||
          null,
      };
      break;
    default:
      break;
  }
  return {
    ...result,
    activePlaceholder: getNextActivePlaceholder(result),
  };
}

export function getNextActivePlaceholder({
  fillInParts,
  answers,
  activePlaceholder,
}: FillInState): string | null {
  const nextActivePlaceholder = fillInParts.find(item =>
    answers.every(
      ({ placeholder }) => placeholder !== item.placeholder && item.placeholder
    )
  );
  return (
    nextActivePlaceholder?.placeholder ||
    (answers.length === 0
      ? fillInParts.find(item => item.placeholder)?.placeholder || null
      : activePlaceholder)
  );
}

export function useFillInState(initialValues: FillInState) {
  const [fillInState, dispatch] = useReducer<
    (prev: FillInState, action: FillInAction) => FillInState
  >(fillInReducer, initialValues);
  return { fillInState, dispatch };
}
