export type UserAnswer = { answer: string; placeholder: string };

export type FillInState = {
  fillInParts: {
    text: string;
    placeholder: string | undefined;
  }[];
  answers: UserAnswer[];
  activePlaceholder: string | null;
};

export enum FillInActionType {
  ADD_USER_ANSWER = 'ADD_USER_ANSWER',
  DESELECT_USER_ANSWER = 'DESELECT_USER_ANSWER',
  DESELECT_LAST_ANSWER = 'DESELECT_LAST_ANSWER',
  RESET = 'RESET',
}

export type FillInAction =
  | { type: FillInActionType.ADD_USER_ANSWER; payload: string }
  | { type: FillInActionType.DESELECT_USER_ANSWER; payload: string }
  | { type: FillInActionType.DESELECT_LAST_ANSWER }
  | { type: FillInActionType.RESET };

export type TaskTool = {
  name: string;
  icon: string;
};
