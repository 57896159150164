import { useGrowthBook } from '@growthbook/growthbook-react';
import { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { userQuery } from '../../../context/atoms/User';
import useAssistantUpsellExperiment from '../../../hooks/use-assistant-upsell-experiment';
import { usePrefetchCurrencySignQuery } from '../../../hooks/use-currency-sign-query';
import { getQueryParamsFromURI } from '../../../utils/QueryParams/QueryParams';
import LanguageNavbar from '../../shared/Navbar/LanguageNavbar';
import {
  useUpsellCascadePrefetch,
  useUpsellSinglePrefetch,
} from '../../upsell';
import { CreateAccountComponent } from './CreateAccountComponent/CreateAccountComponent';
import ProgressComponent from './ProgressBar/ProgressBar';
import { progress_steps } from './ProgressBarData';
import {
  ContainerWithReviews,
  PositionedPageContainer,
  Wrapper,
} from './Signup.styles';
import { getArrowByPrcId, getConfigIdByPrcId } from './lib/utils';

export function SignUpPage() {
  const navigation = useHistory();
  const user = useRecoilValue(userQuery);
  const queryParams = getQueryParamsFromURI();

  const selectedArrow = getArrowByPrcId(queryParams['prc_id']);

  const { value: assistantUpsellTestValue } = useAssistantUpsellExperiment({
    arrow: selectedArrow,
  });

  const growthbook = useGrowthBook();

  const { prefetchUpsellCascadeQuery } = useUpsellCascadePrefetch();
  const { prefetchUpsellSingleQuery } = useUpsellSinglePrefetch();
  const { prefetchCurrencyQuery } = usePrefetchCurrencySignQuery();

  useEffect(() => {
    if (user?.user_id) {
      growthbook?.setAttributes({
        id: user.user_id.toString(),
      });
    }
  }, [user]);

  useEffect(() => {
    prefetchCurrencyQuery();
    prefetchUpsellCascadeQuery();
  }, []);

  useEffect(() => {
    let paywallConfigId = 'giga_upsell';

    prefetchUpsellSingleQuery({ paywallConfigId });
  }, [assistantUpsellTestValue, selectedArrow]);

  function onGoToNextPage(assisstantTestValue?: string) {
    const nextPageUrl = `/upsell-single-giga/giga_upsell?assistant=${assisstantTestValue}`;

    navigation.replace(nextPageUrl);
  }

  return (
    <PositionedPageContainer>
      <LanguageNavbar
        isShadow={false}
        isSticky={false}
        isLogoRedirects={false}
      />

      <Wrapper>
        <ContainerWithReviews>
          <ProgressComponent steps={progress_steps} currentStep={2} />

          <CreateAccountComponent
            assistantTest={assistantUpsellTestValue}
            selectedArrow={selectedArrow}
            events={{
              onGoToNextPage,
            }}
          />
        </ContainerWithReviews>
      </Wrapper>
    </PositionedPageContainer>
  );
}
