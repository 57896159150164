import i18next from 'i18next';

import { analyticsService } from '../../../../../services/analytics.service';
import { useChatBotDetailsQuery } from '../../../entities/chat-details';

type BaseProps = {
  botName: string;
  botId: string;
  sessionId: string | null;
};

export const ChatPageAnalytics = ({
  botName,
  botId,
  sessionId,
}: BaseProps) => ({
  chatPageView({ location }: { location: 'new_chat' | 'existing_chat' }) {
    analyticsService.sendEvent('webapp_bots_view', {
      place: 'chat_page',
      bot_name: botName,
      bot_id: botId,
      chat_id: sessionId,
      location,
      language: i18next.language,
    });
  },
  onInfoButtonClick() {
    analyticsService.sendEvent('webapp_bots_click', {
      place: 'chat_page',
      bot_name: botName,
      bot_id: botId,
      chat_id: sessionId,
      action: 'open_bot_info',
      language: i18next.language,
    });
  },
  onInfoQuestionButtonClick() {
    analyticsService.sendEvent('webapp_bots_click', {
      place: 'chat_page',
      bot_name: botName,
      bot_id: botId,
      chat_id: sessionId,
      action: 'open_help',
      language: i18next.language,
    });
  },
  onChatInfoDeleteButtonClick() {
    analyticsService.sendEvent('webapp_bots_click', {
      place: 'chat_page',
      bot_name: botName,
      bot_id: botId,
      chat_id: sessionId,
      action: 'delete_chat',
      language: i18next.language,
    });
  },
  onChatDetailsModalDeleteButtonClick() {
    analyticsService.sendEvent('webapp_bots_click', {
      place: 'chat_info_modal',
      bot_name: botName,
      bot_id: botId,
      chat_id: sessionId,
      action: 'delete_chat_question',
      language: i18next.language,
    });
  },
  deleteConfirmationModalView() {
    analyticsService.sendEvent('webapp_bots_view', {
      place: 'chat_delete_modal',
      bot_name: botName,
      bot_id: botId,
      chat_id: sessionId,
      language: i18next.language,
    });
  },
  onDeleteConfirmationModalConfirmClick() {
    analyticsService.sendEvent('webapp_bots_click', {
      place: 'chat_delete_modal',
      bot_name: botName,
      bot_id: botId,
      chat_id: sessionId,
      action: 'delete_chat',
      language: i18next.language,
    });
  },
  onFormClick() {
    analyticsService.sendEvent('webapp_bots_click', {
      place: 'chat_page',
      bot_name: botName,
      bot_id: botId,
      chat_id: sessionId,
      action: 'start_prompting',
      language: i18next.language,
    });
  },
  onFormSubmit({
    promptLength,
    resend = false,
  }: {
    promptLength: number;
    resend?: boolean;
  }) {
    analyticsService.sendEvent('webapp_bots_prompt_submit', {
      place: 'chat_page',
      bot_name: botName,
      bot_id: botId,
      chat_id: sessionId,
      action: 'submit_prompt',
      language: i18next.language,
      prompt_length: promptLength,
      resend,
    });
  },
  onReceivesAnswer({
    status,
    errorMessage,
  }: {
    status: 'ok' | 'error';
    errorMessage: string | null;
  }) {
    analyticsService.sendEvent('webapp_bots_prompt_receive', {
      place: 'chat_page',
      bot_name: botName,
      bot_id: botId,
      chat_id: sessionId,
      status,
      error_type: errorMessage,
      language: i18next.language,
    });
  },
  chatDetailsModalView() {
    analyticsService.sendEvent('webapp_bots_view', {
      place: 'chat_info_modal',
      bot_name: botName,
      bot_id: botId,
      chat_id: sessionId,
      language: i18next.language,
    });
  },
  onNewChatButtonClick() {
    analyticsService.sendEvent('webapp_bots_click', {
      place: 'chat_info_modal',
      bot_name: botName,
      bot_id: botId,
      chat_id: sessionId,
      action: 'create_new_chat',
      language: i18next.language,
    });
  },
  onEditChatButtonClick() {
    analyticsService.sendEvent('webapp_bots_click', {
      place: 'chat_info_modal',
      bot_name: botName,
      bot_id: botId,
      chat_id: sessionId,
      action: 'edit_chat_name',
      language: i18next.language,
    });
  },
  onSaveNameChanges() {
    analyticsService.sendEvent('webapp_bots_click', {
      place: 'chat_info_modal',
      bot_name: botName,
      bot_id: botId,
      chat_id: sessionId,
      action: 'save_chat_name',
      language: i18next.language,
    });
  },
});

export function useChatPageAnalytics({
  chatBotId,
  sessionId = null,
}: {
  chatBotId: string;
  sessionId?: string | null;
}) {
  const chatDetailsQuery = useChatBotDetailsQuery({ chatBotId });

  return ChatPageAnalytics({
    botName: chatDetailsQuery.data?.name as string, //TODO: Check the analytics for errors
    botId: chatBotId,
    sessionId,
  });
}
