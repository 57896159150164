import type { AiBotCategory } from '../../../entities/ai-bot';
import './assets/category-button.css';

export function CategoryButton({
  isActive,
  category,
  onClick,
}: {
  isActive?: boolean;
  category: AiBotCategory;
  onClick: (id: AiBotCategory['id']) => void;
}) {
  return (
    <button
      disabled={isActive}
      type="button"
      className={`ai-bots-category-button ${
        isActive ? 'ai-bots-category-button--active' : ''
      }`}
      onClick={() => onClick(category.id)}
    >
      {category.name}
    </button>
  );
}
