import { isEmpty } from 'lodash-es';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BottomSheet } from 'react-spring-bottom-sheet';

import { AiBot } from '../../../entities/ai-bot';
import {
  AiBotsListType,
  useAiBotsListQuery,
} from '../../../feature/ai-bots-list';
import { SessionsPageAnalytics } from '../../../shared/analytics';
import { ErrorBlock } from '../../../shared/errors/error-block';
import { AiBotsListContainer, AiBotsListLoading } from '../../ai-bots-list';
import './explore-new-bots.css';

function BotsList({
  isLoading,
  data,
}: {
  isLoading: boolean;
  data?: AiBotsListType;
}) {
  if (isLoading) {
    return <AiBotsListLoading />;
  }

  if (isEmpty(data)) {
    return <></>;
  }

  function onCategoryButtonClicked() {
    SessionsPageAnalytics.onCategoryFilterClick();
  }

  function onClickToBot(bot: AiBot) {
    SessionsPageAnalytics.onBotClick({
      botName: bot.name,
      botId: bot.id,
    });
  }

  function onSearchFormClick() {
    SessionsPageAnalytics.onSearchFormClick({ place: 'bot_selection_modal' });
  }

  function onSearchFormSubmit(resultCount: number) {
    SessionsPageAnalytics.onSearchFormSubmit({
      place: 'bot_selection_modal',
      resultCount,
    });
  }

  return (
    <AiBotsListContainer
      data={data}
      withSearchForm={true}
      onCategoryButtonClicked={onCategoryButtonClicked}
      onClickToBot={onClickToBot}
      onSearchFormSubmit={onSearchFormSubmit}
      onSearchFormClick={onSearchFormClick}
    />
  );
}

export function ExploreNewBotsBottomSheet({
  isVisible,
  onDismiss,
}: {
  isVisible: boolean;
  onDismiss: () => void;
}) {
  const { t } = useTranslation();
  const aiBotsListQuery = useAiBotsListQuery();

  useEffect(() => {
    if (isVisible && !isEmpty(aiBotsListQuery.data)) {
      SessionsPageAnalytics.botsListModalView();
    }
  }, [isVisible, aiBotsListQuery.data]);

  return (
    <BottomSheet
      key="explore-new-bots-bottomsheet"
      expandOnContentDrag={true}
      snapPoints={({ maxHeight }) => [maxHeight - maxHeight / 5]}
      open={isVisible}
      onDismiss={() => onDismiss()}
    >
      <div className="explore-new-bots">
        <div className="explore-new-bots__heading">
          <h1 className="explore-new-bots__title">
            {t('ai-bots.explore-bots-title')}
          </h1>
          <button
            type="button"
            className="explore-new-bots__close-button"
            onClick={() => onDismiss()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              fill="none"
            >
              <path
                fill="#565B66"
                d="M9.293 9.293a1 1 0 0 1 1.414 0L16 14.586l5.293-5.293a1 1 0 1 1 1.414 1.414L17.414 16l5.293 5.293a1 1 0 0 1-1.414 1.414L16 17.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L14.586 16l-5.293-5.293a1 1 0 0 1 0-1.414Z"
              />
            </svg>
          </button>
        </div>
        {aiBotsListQuery.isError ? (
          <ErrorBlock onRetry={() => aiBotsListQuery.refetch()} />
        ) : (
          <BotsList
            isLoading={aiBotsListQuery.isLoading}
            data={aiBotsListQuery.data}
          />
        )}
      </div>
    </BottomSheet>
  );
}
