import dayjs from 'dayjs';
import { last } from 'lodash-es';

import { AiBotSessionType } from '../../session';
import './ai-bot-card.css';

export function AiBotSessionCard({
  data,
  onClick,
}: {
  data: AiBotSessionType;
  onClick: () => void;
}) {
  const lastQuery = last(data.queries);
  const lastQueryText = lastQuery?.response ?? lastQuery?.text;
  const createdAt = dayjs(data.created_at).isToday()
    ? dayjs(data.created_at).format('HH:mm')
    : dayjs(data.created_at).format('DD MMM');

  return (
    <div className="ai-bot-card ai-bot-session-card" onClick={() => onClick()}>
      <div className="ai-bot-card__container">
        <img
          className="ai-bot-card__image"
          width="64"
          height="64"
          src={data.chatbot.image || ''} //@note For custom bots add default image (or make image required)
          role="presentation"
          alt=""
        />
        <div className="ai-bot-card__content">
          <h2>{data.name}</h2>
          <h3>{data.chatbot.name}</h3>
          {lastQueryText && (
            <p className="ai-bot-card__last-message">{lastQueryText}</p>
          )}
        </div>
      </div>
      <small>{createdAt}</small>
    </div>
  );
}
