import { useTranslation } from 'react-i18next';

import { usePersonalProfileQuery } from '../../../../../entities/personal-profile';
import { GuideType } from '../../../../guides/entities/guide';
import { useGuidesOnboarding } from '../../../../guides/entities/guides-onboarding';
import { UiPrimaryButton } from '../../../../shared/ui';
import './ongoing-guide-v2.css';

export function OngoingGuideV2({
  guide,
  progress,
  onContinue,
}: {
  guide: GuideType;
  progress: number;
  onContinue: (guide: GuideType, isPathway: boolean) => void;
}) {
  const { t } = useTranslation();
  const { isGuidesOnboardingVisible } = useGuidesOnboarding();

  const userPersonalProfile = usePersonalProfileQuery(true);
  const arrow = userPersonalProfile?.data?.personalization_criteria?.arrow?.[0];

  const localizedTitle = arrow
    ? `guides-v2.${arrow}-arrow`
    : 'guides-v2.ai-arrow';

  return (
    <div className="ongoing-guide">
      <div className="ongoing-guide-v2__image-container">
        <img
          width="124"
          height="124"
          src={guide.image}
          role="presentation"
          alt=""
        />
      </div>

      <p className="ongoing-guide-v2__category">{t(localizedTitle)}</p>

      <h1 className="ongoing-guide__title">{guide.name}</h1>
      <div className="ongoing-guide__progress">
        <div
          className="ongoing-guide__progress-line"
          style={{ width: `${progress}%` }}
        ></div>
      </div>

      <div className="ongoing-guide__actions">
        {isGuidesOnboardingVisible && (
          <div className="ongoing-guide__tip">{t('guides.tip-text')}</div>
        )}

        <UiPrimaryButton onClick={() => onContinue(guide, true)}>
          {t('guides.continue-learning-button')}
        </UiPrimaryButton>
      </div>
    </div>
  );
}
