import i18next from 'i18next';

import { analyticsService } from '../../../../../services/analytics.service';

export const MainPageAnalytics = {
  botsListView() {
    analyticsService.sendEvent('webapp_bots_view', {
      place: 'home_page',
      feature_version: 'v1',
      language: i18next.language,
    });
  },
  onBotClick({
    location,
    botName,
    botId,
    action,
  }: {
    location: 'top' | 'explore' | 'home_filter';
    botName: string;
    botId: string;
    action: 'create_new_chat' | 'open_existing_chat';
  }) {
    analyticsService.sendEvent('webapp_bots_click', {
      place: 'home_page',
      location,
      bot_name: botName,
      bot_id: botId,
      action,
      language: i18next.language,
    });
  },
  onCategoryFilterClick() {
    analyticsService.sendEvent('webapp_bots_click', {
      place: 'home_page',
      location: 'home_filter',
      action: 'filter_results',
      language: i18next.language,
    });
  },
};
