import { first, isEmpty } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';

import { AiBot } from '../../../../entities/ai-bot';
import { AiBotCard, AiBotSessionCard } from '../../../../entities/ai-bot-card';
import type { AiBotSessionType } from '../../../../entities/session';
import { useAiBotsListQuery } from '../../../../feature/ai-bots-list';
import { MainPageAnalytics } from '../../../../shared/analytics';
import './active-chat.css';

function StartNewChat({
  onClickToBot,
}: {
  onClickToBot?: (bot: AiBot) => void;
}) {
  const { t } = useTranslation();
  const { data } = useAiBotsListQuery();

  if (!data) {
    throw new Error('ERR_AI_BOTS_START_NEW_CHAT_RENDER_WITHOUT_DATA');
  }

  const navigation = useHistory();

  const firstDefaultChatbot = data.defaults[0];

  function goToNewChat() {
    onClickToBot?.(firstDefaultChatbot);
    navigation.push(`/ai-bots/new-chat/${firstDefaultChatbot.id}`, {
      from: navigation.location.pathname,
    });
  }

  return (
    <div className="active-chat" onClick={goToNewChat}>
      <h1 className="active-chat__title">{t('ai-bots.new-chat-title')}</h1>
      <AiBotCard data={firstDefaultChatbot} />
    </div>
  );
}

function ActiveSession({
  sessions,
  onClickToBot,
}: {
  sessions?: Array<AiBotSessionType>;
  onClickToBot?: (bot: AiBot) => void;
}) {
  if (!sessions) {
    throw new Error('ERR_AI_BOTS_ACTIVE_CHAT_RENDER_WITHOUT_SESSIONS_DATA');
  }

  const navigation = useHistory();
  const { t } = useTranslation();

  /**
   * @note
   * Assertion in this case is ok,
   * because the component will not be rendered if the data is empty
   * */
  const lastActiveSession = first(sessions) as AiBotSessionType;

  function goToSession() {
    onClickToBot?.(lastActiveSession?.chatbot);
    navigation.push(
      `/ai-bots/session/${lastActiveSession.chatbot.id}/${lastActiveSession.id}`,
      {
        from: navigation.location.pathname,
      }
    );
  }

  return (
    <div className="active-chat">
      <div className="active-chat__container">
        <h1 className="active-chat__title">
          {t('ai-bots.last-session-title')}
        </h1>
        <Link
          to={location => ({
            ...location,
            pathname: '/ai-bots/sessions',
            state: { from: navigation.location.pathname },
          })}
          className="active-chat__link"
        >
          {t('ai-bots.view-all-link')}
        </Link>
      </div>
      <AiBotSessionCard data={lastActiveSession} onClick={goToSession} />
    </div>
  );
}

export function ActiveChat({
  sessionsData,
}: {
  sessionsData?: Array<AiBotSessionType>;
}) {
  const isSessionsExist = !isEmpty(sessionsData);

  function onClickToSessionBot(bot: AiBot) {
    MainPageAnalytics.onBotClick({
      location: 'top',
      botId: bot.id,
      botName: bot.name,
      action: 'open_existing_chat',
    });
  }

  function onClickToNewBot(bot: AiBot) {
    MainPageAnalytics.onBotClick({
      location: 'top',
      botId: bot.id,
      botName: bot.name,
      action: 'create_new_chat',
    });
  }

  return isSessionsExist ? (
    <ActiveSession sessions={sessionsData} onClickToBot={onClickToSessionBot} />
  ) : (
    <StartNewChat onClickToBot={onClickToNewBot} />
  );
}
