import Bugsnag from '@bugsnag/js';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import { useGrowthBook } from '@growthbook/growthbook-react';
import dayjs from 'dayjs';
import Cookies from 'js-cookie';
import { isNil } from 'lodash-es';
import { useEffect, useState } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import './App.scss';
import { languageState } from './context/atoms/Language';
import {
  localGraceSubscriptionState,
  localOnboardState,
  userAccessQuery,
  userDataQuery,
  userQuery,
} from './context/atoms/User';
import { usePersonalProfileQuery } from './entities/personal-profile';
import ProfileRoutes from './modules/Profile/ProfileRoutes';
import {
  AiBotSessionsPage,
  AiBotsMainPage,
  AiBotsNewChatPage,
  AiBotsSessionPage,
} from './modules/ai-bots';
import {
  ChallengeGuideCompletePage,
  ChallengeInfoPage,
  ChallengeLessonPage,
  ChallengeSurveyPage,
  ChallengeTasksPage,
  ChallengesJoinedPage,
  ChallengesMainPage,
  usePrefetchChallengesListProgressQuery,
  usePrefetchChallengesListQuery,
} from './modules/challenges';
import {
  GuideByIdPage,
  GuideCompletePage,
  GuideLessonPage,
  GuideSurveyPage,
  GuidesByCategoryPage,
  GuidesMainPage,
} from './modules/guides';
import { GuidesMainPageV2 } from './modules/guides-v2';
import { LearningPathway } from './modules/guides-v2/pages/learning-pathway';
import { OtherGuides } from './modules/guides-v2/pages/other-guides/ui/OtherGuides';
import {
  OnboardingInfoPage,
  OnboardingLevelInfoPage,
  OnboardingLevelPickerPage,
  OnboardingQuizPage,
  OnboardingWelcomePage,
} from './modules/onboarding';
import { AiToolPage } from './modules/onboarding-v2/pages/ai-tool-page';
import { OnboardingV2CongratsPage } from './modules/onboarding-v2/pages/congrats-page';
import { OnboardingV2IntroLessonPage } from './modules/onboarding-v2/pages/intro-lesson-page';
import { OnboardingV2PersonalPlanPage } from './modules/onboarding-v2/pages/personal-plan-page';
import { StartOnboardingPage } from './modules/onboarding-v2/pages/start-onboarding-page';
import { OnboardingV2SurveyPage } from './modules/onboarding-v2/pages/survey-page';
import {
  SatisfactionSurveyBottomSheet,
  useSatisfactionSurveyModel,
} from './modules/satisfaction-survey';
import { Loader } from './modules/shared';
import PageNotFound from './modules/shared/PageNotFound/PageNotFound';
import {
  UpsellCascadePage,
  UpsellCascadeRedesignPage,
  UpsellSingleGigaPage,
  UpsellSinglePage,
} from './modules/upsell';
import { getArrow } from './modules/upsell/upsell-cascade/lib';
import { UpsellSingleGigaAssistantPage } from './modules/upsell/upsell-single';
import { WebToAppPage } from './modules/web-to-app';
import { analyticsService } from './services/analytics.service';
import useAiPathwayExperiment from './shared/hooks/use-ai-pathway-experiment';
import usePaymentHistoryExperiment from './shared/hooks/use-payment-history-experiment';
import {
  getExistedAppLanguage,
  setAppLanguage,
} from './utils/Language/Language';

export function AppRoutes() {
  const location = useLocation();
  const sendDataToGTM = useGTMDispatch();
  const growthBook = useGrowthBook();
  const navigation = useHistory();

  const user = useRecoilValue(userQuery);
  const userId = user?.user_id!;
  const userAccess = useRecoilValue(userAccessQuery(user?.user_id));
  const userData = useRecoilValue(userDataQuery);
  const { prefetchChallengesListQuery } = usePrefetchChallengesListQuery();
  const { prefetchChallengesListProgressQuery } =
    usePrefetchChallengesListProgressQuery();

  const userPersonalProfile = usePersonalProfileQuery(true);
  const arrow = userPersonalProfile?.data?.personalization_criteria?.arrow?.[0];
  const filteredArrow = getArrow(arrow);

  const localOnboard = useRecoilValue(localOnboardState);
  const setLanguage = useSetRecoilState(languageState);
  const [isInitial, setIsInitial] = useState(true);
  const setLocalGraceSubscription = useSetRecoilState(
    localGraceSubscriptionState
  );

  const { value: aiPathwayExperiment } = useAiPathwayExperiment({
    isLoading: userPersonalProfile.isLoading || !userData,
  });

  const paymentHistoryExperimentValue = usePaymentHistoryExperiment();

  const onboardingExcludedPaths = [
    'auth',
    'onboarding',
    'upsell',
    'web-to-app',
  ];
  const isOnboardingExcluded = !onboardingExcludedPaths.some(path =>
    navigation.location.pathname.includes(path)
  );

  const { isSatisfactionSurveyVisible, onSatisfactionSurveyDismiss } =
    useSatisfactionSurveyModel(userData);

  useEffect(() => {
    prefetchChallengesListQuery();
    prefetchChallengesListProgressQuery();
  }, []);

  useEffect(() => {
    if (
      !userData?.is_onboarded &&
      !localOnboard &&
      user &&
      isOnboardingExcluded &&
      userPersonalProfile.data
    ) {
      const onboardingPath = filteredArrow
        ? `/onboarding/v2?arrow=${filteredArrow}`
        : '/onboarding/v2';
      navigation.replace(onboardingPath);
    }
  }, [userData, localOnboard, navigation, user, userPersonalProfile]);

  function handleSetLanguage() {
    if (!isInitial) {
      return;
    }

    setIsInitial(false);

    if (Cookies.get('set_lang')) {
      setAppLanguage(Cookies.get('set_lang')!, userId);
      setLanguage(Cookies.get('set_lang')!);
      Cookies.remove('set_lang');
      return;
    }

    if (userData!.language) {
      setLanguage(getExistedAppLanguage(userData!.language));
      setAppLanguage(getExistedAppLanguage(userData!.language), userId, false);
      return;
    }
  }

  useEffect(() => {
    sendDataToGTM({ event: 'pageview', page: { url: location.pathname } });
  }, [location]);

  useEffect(() => {
    if (!userData || !userData.id || !userPersonalProfile.data) {
      return;
    }

    const userArrow =
      userPersonalProfile.data.personalization_criteria.arrow[0];

    handleSetLanguage();

    growthBook?.setAttributes({
      ...growthBook?.getAttributes(),
      id: userData.id.toString(),
      registered_at: dayjs(userData?.registered_at).format('YYYY-MM-DDTHH:mm'),
      arrow: userArrow,
    });

    Bugsnag.setUser(userData.id.toString(), userData.email);

    analyticsService.setUserId(userData.id.toString());

    setLocalGraceSubscription(userData.is_grace_subscription);
  }, [userData, userPersonalProfile.data]);

  //TODO: refactor user access in app ASAP

  useEffect(() => {
    if (!isNil(userAccess.has_access)) {
      analyticsService.sendEvent('pr_webapp_visit', {
        has_access: userAccess.has_access,
      });
    }

    if (!isNil(userAccess.has_access) && !userAccess.has_access) {
      navigation.push('/profile');
    }
  }, [userAccess]);

  useEffect(() => {
    analyticsService.setAmplitudeUserProperties({
      exp_profile_payment_history_v1: paymentHistoryExperimentValue,
    });
  }, [paymentHistoryExperimentValue]);

  if (isNil(userAccess.has_access)) {
    return <Loader />;
  }

  return (
    <div className="app">
      <div className="app_view" id="app_view">
        <Switch>
          <Route path="/profile" component={ProfileRoutes} />

          <Route path="/upsell-cascade" component={UpsellCascadePage} />

          <Route
            path="/upsell-cascade-redesign"
            component={UpsellCascadeRedesignPage}
          />

          <Route
            path="/upsell-single/:paywallConfigId"
            component={UpsellSinglePage}
          />
          <Route
            path="/upsell-single-giga/:paywallConfigId"
            component={UpsellSingleGigaPage}
          />

          <Route
            path="/upsell-assistant"
            component={UpsellSingleGigaAssistantPage}
          />

          <Route path="/web-to-app" component={WebToAppPage} />

          <Route exact path="/onboarding" component={OnboardingWelcomePage} />

          <Route path="/onboarding/info" component={OnboardingInfoPage} />

          <Route path="/onboarding/quiz" component={OnboardingQuizPage} />

          <Route
            path="/onboarding/level-picker"
            component={OnboardingLevelPickerPage}
          />

          <Route
            path="/onboarding/level-info"
            component={OnboardingLevelInfoPage}
          />

          <Route
            exact
            path="/onboarding/v2"
            component={OnboardingV2PersonalPlanPage}
          />
          <Route exact path="/onboarding/v2/ai-tool" component={AiToolPage} />
          <Route
            exact
            path="/onboarding/v2/start-onboarding"
            component={StartOnboardingPage}
          />
          <Route
            exact
            path="/onboarding/v2/intro-lesson"
            component={OnboardingV2IntroLessonPage}
          />

          <Route
            exact
            path="/onboarding/v2/congrats"
            component={OnboardingV2CongratsPage}
          />

          <Route
            exact
            path="/onboarding/v2/survey"
            component={OnboardingV2SurveyPage}
          />

          <Route exact path="/ai-bots" component={AiBotsMainPage} />

          <Route
            path="/ai-bots/new-chat/:chatBotId"
            component={AiBotsNewChatPage}
          />

          <Route
            path="/ai-bots/session/:chatBotId/:sessionId"
            component={AiBotsSessionPage}
          />

          <Route path="/ai-bots/sessions" component={AiBotSessionsPage} />

          {aiPathwayExperiment === 'test' ? (
            <Route exact path="/" component={GuidesMainPageV2} />
          ) : (
            <Route exact path="/" component={GuidesMainPage} />
          )}
          <Route exact path="/other-guides" component={OtherGuides} />

          <Route exact path="/challenges" component={ChallengesMainPage} />

          <Route
            exact
            path="/challenges/joined"
            component={ChallengesJoinedPage}
          />

          <Route
            exact
            path="/challenges/:challengeId"
            component={ChallengeInfoPage}
          />

          <Route
            exact
            path="/challenges/:challengeId/tasks"
            component={ChallengeTasksPage}
          />

          <Route
            exact
            path="/challenges/:challengeId/:taskId"
            component={ChallengeLessonPage}
          />

          <Route
            exact
            path="/challenges/:challengeId/:taskId/complete"
            component={ChallengeGuideCompletePage}
          />

          <Route
            exact
            path="/challenges/:challengeId/:taskId/survey"
            component={ChallengeSurveyPage}
          />

          <Route
            exact
            path="/guides/category/:category"
            component={GuidesByCategoryPage}
          />
          <Route exact path="/guides/:guideId" component={GuideByIdPage} />
          <Route
            exact
            path="/guide-pathway/:guideId"
            component={LearningPathway}
          />
          <Route
            exact
            path="/guides/:guideId/:unitId/:lessonId"
            component={GuideLessonPage}
          />
          <Route
            exact
            path="/guides/:guideId/:unitId/:lessonId/complete"
            component={GuideCompletePage}
          />
          <Route
            exact
            path="/guides/:guideId/:unitId/:lessonId/survey"
            component={GuideSurveyPage}
          />

          <Route path="/404" component={PageNotFound} />

          <Route path="*" render={() => <Redirect to="/404" />} />
        </Switch>
      </div>

      <SatisfactionSurveyBottomSheet
        isVisible={isSatisfactionSurveyVisible}
        onDismiss={onSatisfactionSurveyDismiss}
      />
    </div>
  );
}
