import { isEmpty } from 'lodash-es';
import { useTranslation } from 'react-i18next';

import {
  type AiBotMessageType,
  type AiBotNewMessage,
  ChatMessage,
  NewMessage,
} from '../../../entities/message';
import { UiSpinner } from '../../../shared/ui/spinner';

function EmptyChatList() {
  const { t } = useTranslation();

  return (
    <div className="ai-bot-chat__list-center">
      {t('ai-bots.chat.empty-results')}
    </div>
  );
}

function LoadingChatList() {
  return (
    <div className="ai-bot-chat__list-center">
      <UiSpinner />
    </div>
  );
}

export function ChatList({
  isMessagesLoading = false,
  newMessage,
  messages = [],
  onOpenImage,
}: {
  isMessagesLoading?: boolean;
  newMessage: AiBotNewMessage | null;
  messages?: Array<AiBotMessageType>;
  onOpenImage?: (url: string) => void;
}) {
  if (isMessagesLoading) {
    return <LoadingChatList />;
  }

  if (!newMessage && isEmpty(messages)) {
    return <EmptyChatList />;
  }

  return (
    <div className="ai-bot-chat__list">
      {messages.map(message => (
        <ChatMessage
          key={message.id}
          message={message}
          onOpenImage={onOpenImage}
        />
      ))}
      {newMessage && <NewMessage newMessage={newMessage} />}
    </div>
  );
}
