import { isEqual, shuffle } from 'lodash-es';

import { GuideLessonDetailsFillTheGapType } from '../../../entities/guide-lesson/types';
import { TaskTool, UserAnswer } from '../lib/types';
import FillInIcon from '../ui/assets/fill-in-blanks.svg';
import GPTAvatarIcon from '../ui/assets/gpt-avatar-icon.png';

export const shuffleArray = (array: string[]) => {
  if (array.length <= 1) {
    return array;
  }

  let shuffledArray;
  do {
    shuffledArray = shuffle(array);
  } while (isEqual(array, shuffledArray));
  return shuffledArray;
};

export const splitTextIntoParagraphs = (text: string) => {
  const matches = text.match(/.{1,15}(?=\s|,|$)|,/g);
  if (matches) {
    return matches.flatMap(item => {
      if (item.startsWith(',')) {
        return [',', item.slice(1).trim()];
      }
      return [item.trim()];
    });
  }

  return matches;
};

export const getFillInParts = (task: GuideLessonDetailsFillTheGapType) => {
  return task.content[0].text
    .split(/(\[.*?\])/)
    .map(part => {
      const match = part.match(/\[(.*?)\]/);
      if (match) {
        const placeholder = match[1];
        return {
          text: part.replace(match[0], ''),
          placeholder,
        };
      }
      return { text: part, placeholder: '' };
    })
    .filter(part => part.text !== '' || part.placeholder);
};

export function smoothScrollToBottom() {
  const pageContainer = document.querySelector('.ui-base-layout');

  if (pageContainer) {
    // @note setTimeout - For deferred execution after rendering
    setTimeout(() => {
      window.scrollTo({
        top: pageContainer.scrollHeight,
        left: 0,
        behavior: 'smooth',
      });
    });
  }
}

export function generateCustomerMessage(
  initFillInParts: ReturnType<typeof getFillInParts>,
  answers: UserAnswer[]
): string {
  return (
    initFillInParts
      ?.map(
        item =>
          `${item.text} ${
            answers.find(({ placeholder }) => placeholder === item.placeholder)
              ?.answer || ''
          }`
      )
      .join(' ') || ''
  );
}

export function getTool(
  isOnboardingArrow: boolean | undefined,
  fillInText: string,
  tool?: TaskTool
): TaskTool {
  if (isOnboardingArrow) {
    return {
      name: fillInText,
      icon: FillInIcon,
    };
  }

  if (tool) {
    return {
      name: tool.name,
      icon: tool.icon,
    };
  }

  return {
    name: 'ChatGPT',
    icon: GPTAvatarIcon,
  };
}
