import { Trans, useTranslation } from 'react-i18next';
import { BottomSheet } from 'react-spring-bottom-sheet';

import { UiPrimaryButton } from '../../../shared/ui';
import './assets/smoke-purchase-bottomsheet.css';

export function SmokePurchaseBottomsheet({
  isVisible,
  events,
}: {
  isVisible: boolean;
  events: {
    onClose: () => void;
  };
}) {
  const { t } = useTranslation(undefined, {
    keyPrefix: `upsell-single-giga.assistant`,
  });

  return (
    <BottomSheet key="new-smoke-purchase" open={isVisible}>
      <div className="smoke-purchase">
        <div className="smoke-purchase__container">
          <h1 className="smoke-purchase__title">{t('smoke-purchase.title')}</h1>
          <p className="smoke-purchase__description">
            <Trans
              i18nKey={t('smoke-purchase.description')}
              components={{ b: <b /> }}
            />
          </p>

          <div className="smoke-purchase__actions">
            <UiPrimaryButton onClick={events.onClose}>
              {t('chase-block.action')}
            </UiPrimaryButton>
          </div>
        </div>
      </div>
    </BottomSheet>
  );
}
