import AiAvatarIcon from './assets/ai-avatar.svg';
import './assets/ai-bot-message.css';

export function AiImageMessage({
  url,
  onOpenImage,
}: {
  url: string | null;
  onOpenImage?: (url: string) => void;
}) {
  return (
    <div className="ai-bot-message ai-bot-message_ai-message">
      <img
        width="24"
        height="24"
        src={AiAvatarIcon}
        role="presentation"
        alt=""
      />
      <div className="ai-bot-message__image-container">
        {url && (
          <img
            className="ai-bot-message__image"
            src={url}
            alt=""
            onClick={() => onOpenImage?.(url)}
          />
        )}
      </div>
    </div>
  );
}
