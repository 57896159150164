import {
  useExperiment,
  useFeatureValue,
  useGrowthBook,
} from '@growthbook/growthbook-react';
import dayjs from 'dayjs';

function isAfterDate(register_date?: string, expStartDate?: string) {
  if (!register_date || !expStartDate) {
    return false;
  }

  const registrationDate = dayjs(register_date);
  const experimentStartDate = dayjs(expStartDate);

  return (
    registrationDate.isAfter(experimentStartDate) ||
    registrationDate.isSame(experimentStartDate)
  );
}
export default function useAiPathwayExperiment({
  isLoading,
}: {
  isLoading: boolean;
}) {
  const growthbook = useGrowthBook();
  const { arrow, registered_at } = growthbook?.getAttributes() ?? {};

  const aiExpStartDate = useFeatureValue('exp_ai_pathway_date', null);
  const aiExpEndDate = useFeatureValue('exp_ai_pathway_end_date', null);

  const expStartDate = useFeatureValue('exp_pathway_date', null);

  const isExperimentActive = (startDate: string | null) =>
    !isLoading &&
    registered_at &&
    startDate &&
    isAfterDate(registered_at, startDate);

  const experimentConfig = {
    ai: {
      key: 'ab_pathway_ai',
      isActive: isExperimentActive(aiExpStartDate) && arrow === 'ai',
    },
    remote_work: {
      key: 'ab_pathway_remote_work',
      isActive: false,
    },
    influencer: {
      key: 'ab_pathway_influencer',
      isActive: isExperimentActive(expStartDate) && arrow === 'influencer',
    },
  };

  const experimentKey =
    experimentConfig[arrow as keyof typeof experimentConfig]?.key ?? '';
  const isActive =
    experimentConfig[arrow as keyof typeof experimentConfig]?.isActive ?? false;

  const { value } = useExperiment<'clean' | 'test'>({
    key: experimentKey,
    variations: ['test', 'clean'],
    active: isActive,
  });

  if (
    experimentKey === 'ab_pathway_ai' &&
    aiExpEndDate &&
    registered_at &&
    isAfterDate(registered_at, aiExpEndDate)
  ) {
    return { value: 'test' };
  }
  return { value: isActive ? value ?? 'default' : 'default' };
}
