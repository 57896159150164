import { isEmpty, last } from 'lodash-es';

import type { AiBot } from '../../../entities/ai-bot';
import {
  type AiBotMessageType,
  AiBotNewMessage,
} from '../../../entities/message';
import { ChatForm } from '../../../feature/chat-form';
import { ChatInfo } from '../../../feature/chat-info';
import { ChatList } from '../../../feature/chat-list';
import './ai-bot-chat.css';

export function ChatContainer({
  chatBotId,
  newMessage,
  isMessagesLoading = false,
  isDeleteSessionButtonVisible = false,
  messages = [],
  onShowInfo,
  onDeleteSession,
  onSubmitMessage,
  onRegeneratePrompt,
  onFormClick,
  onOpenImage,
}: {
  chatBotId: string;
  newMessage: AiBotNewMessage | null;
  isMessagesLoading?: boolean;
  isDeleteSessionButtonVisible?: boolean;
  messages?: Array<AiBotMessageType>;
  onShowInfo?: (info: AiBot) => void;
  onDeleteSession?: () => void;
  onSubmitMessage: (text: string) => void;
  onRegeneratePrompt: () => void;
  onFormClick?: () => void;
  onOpenImage?: (url: string) => void;
}) {
  const hasNewMessage = !isEmpty(newMessage);
  const lastMessage = last(messages);

  const isLastMessageResponseEmpty =
    Boolean(lastMessage) && isEmpty(lastMessage?.response);

  const isPendingAnswer = hasNewMessage || isLastMessageResponseEmpty;

  return (
    <section className="ai-bot-chat">
      <ChatInfo
        chatBotId={chatBotId}
        isDeleteSessionButtonVisible={isDeleteSessionButtonVisible}
        onShowInfo={chatBotDetails => onShowInfo?.(chatBotDetails)}
        onDeleteSession={() => onDeleteSession?.()}
      />
      <ChatList
        isMessagesLoading={isMessagesLoading}
        newMessage={newMessage}
        messages={messages}
        onOpenImage={onOpenImage}
      />
      <ChatForm
        newMessage={newMessage}
        isPendingAnswer={isPendingAnswer}
        isMessagesLoading={isMessagesLoading}
        onSubmit={text => onSubmitMessage(text)}
        onFormClick={onFormClick}
        onRegeneratePrompt={onRegeneratePrompt}
      />
    </section>
  );
}
