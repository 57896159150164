import classNames from 'classnames';
import i18next from 'i18next';
import { Trans, useTranslation } from 'react-i18next';
import { BottomSheet } from 'react-spring-bottom-sheet';
import { DefaultValue } from 'recoil';

import DiscountImageEN from '../../../../images/upsell_discount_en.png';
import DiscountImageES from '../../../../images/upsell_discount_es.png';
import { UiPrimaryButton } from '../../../shared/ui';
import './assets/chase-bottomsheet.css';

export function ChaseBottomsheet({
  isVisible,
  events,
  upsellType,
  headingExpValue,
}: {
  isVisible: boolean;
  events: {
    onClose: () => void;
  };
  upsellType: string;
  headingExpValue?: string | null;
}) {
  const { t } = useTranslation(undefined, {
    keyPrefix: `upsell-single-giga.${upsellType}`,
  });

  const isESLocale = i18next.language === 'es';

  const isGigaUpsell = upsellType === 'giga_upsell';

  const gigaUpsellImage = isESLocale ? DiscountImageES : DiscountImageEN;
  const chaseImage = t('chase-block.image');

  return (
    <BottomSheet key="new-upsell-chase-bottomsheet" open={isVisible}>
      <div className="upsell-chase-bottomsheet">
        <div className="upsell-chase-bottomsheet__container">
          <h1
            className={classNames('upsell-chase-bottomsheet__title', {
              'upsell-chase-bottomsheet__title-assistant':
                upsellType === 'assistant' || upsellType === 'business',
            })}
          >
            {t('chase-block.title')}
          </h1>
          <p
            className={classNames('upsell-chase-bottomsheet__description', {
              'upsell-chase-bottomsheet__description-assistant':
                upsellType === 'assistant' || upsellType === 'business',
            })}
          >
            <Trans
              i18nKey={t(`${headingExpValue}.chase-block.description`, {
                defaultValue: t('chase-block.description'),
              })}
              components={{ b: <b /> }}
            />
          </p>

          <img
            className="upsell-chase-bottomsheet__image"
            width="100%"
            height="200"
            src={isGigaUpsell ? gigaUpsellImage : chaseImage}
            role="presentation"
            alt=""
          />

          <div
            className={classNames('upsell-chase-bottomsheet__quote', {
              'upsell-chase-bottomsheet__quote-assistant':
                upsellType === 'assistant' || upsellType === 'business',
            })}
          >
            <Trans i18nKey={t('chase-block.quote')} components={{ b: <b /> }} />
          </div>

          <div className="upsell-chase-bottomsheet__actions">
            <UiPrimaryButton onClick={events.onClose}>
              {t('chase-block.action')}
            </UiPrimaryButton>
          </div>
        </div>
      </div>
    </BottomSheet>
  );
}
