import i18next from 'i18next';

import { analyticsService } from '../../../../services/analytics.service';

export const GuidesV2Analytics = {
  // Main page
  mainPageView() {
    analyticsService.sendEvent('webapp_pathway_view', {
      place: 'pathway_selection_page',
      exp_pathway_v1: 'test',
      language: i18next.language,
    });
  },
  onOngoingGuideClick({ guideId }: { guideId: string }) {
    analyticsService.sendEvent('webapp_pathway_click', {
      place: 'pathway_selection_page',
      action: 'continue_learning',
      guide_id: guideId,
      exp_pathway_v1: 'test',
      language: i18next.language,
    });
  },
  onGuideCardClick({ guideId }: { guideId: string }) {
    analyticsService.sendEvent('webapp_pathway_click', {
      place: 'pathway_selection_page',
      action: 'open_pathway',
      guide_id: guideId,
      exp_pathway_v1: 'test',
      language: i18next.language,
    });
  },

  // Pathway page
  pathwayPageView({
    guide_id,
    unit_id,
  }: {
    guide_id: string;
    unit_id: string;
  }) {
    analyticsService.sendEvent('webapp_pathway_view', {
      place: 'pathway_page',
      guide_id,
      unit_id,
      exp_pathway_v1: 'test',
      language: i18next.language,
    });
  },
  onLessonCoastClick({
    guide_id,
    unit_id,
    lesson_id,
    state,
  }: {
    guide_id: string;
    unit_id: string;
    lesson_id: string;
    state: 'not_completed' | 'completed';
  }) {
    analyticsService.sendEvent('webapp_pathway_click', {
      place: 'pathway_page',
      action: 'click_coast',
      guide_id,
      unit_id,
      lesson_id,
      state,
      exp_pathway_v1: 'test',
      language: i18next.language,
    });
  },
  onStartLessonClick({
    guide_id,
    unit_id,
    lesson_id,
    state,
  }: {
    guide_id: string;
    unit_id: string;
    lesson_id: string;
    state: 'not_completed' | 'completed';
  }) {
    analyticsService.sendEvent('webapp_pathway_click', {
      place: 'pathway_page',
      action: 'start_lesson',
      guide_id,
      unit_id,
      lesson_id,
      state,
      exp_pathway_v1: 'test',
      language: i18next.language,
    });
  },
  onInfoClick({
    guide_id,
    unit_id,
    state,
  }: {
    guide_id: string;
    unit_id: string;
    state: 'description_opened' | 'description_closed';
  }) {
    analyticsService.sendEvent('webapp_pathway_click', {
      place: 'pathway_page',
      action: 'click_info',
      guide_id,
      unit_id,
      state,
      exp_pathway_v1: 'test',
      language: i18next.language,
    });
  },
  onJumpAheadClick({ guide_id }: { guide_id: string }) {
    analyticsService.sendEvent('webapp_pathway_click', {
      place: 'pathway_page',
      action: 'open_next_level',
      guide_id,
      exp_pathway_v1: 'test',
      language: i18next.language,
    });
  },

  // Dropdown navigation
  onDropdownClick({ guide_id }: { guide_id: string }) {
    analyticsService.sendEvent('webapp_pathway_click', {
      place: 'pathway_page',
      action: 'open_dropdown',
      guide_id,
      exp_pathway_v1: 'test',
      language: i18next.language,
    });
  },

  onToolClick({
    current_guide_id,
    guide_id,
  }: {
    current_guide_id: string;
    guide_id: string;
  }) {
    analyticsService.sendEvent('webapp_pathway_click', {
      place: 'pathway_page',
      action: 'open_another_pathway',
      current_guide_id,
      guide_id,
      exp_pathway_v1: 'test',
      language: i18next.language,
    });
  },
};
