import {
  useFeatureIsOn,
  useFeatureValue,
  useGrowthBook,
} from '@growthbook/growthbook-react';
import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { userDataQuery } from '../../../../context/atoms/User';
import { ApplePayButton, useApplePay } from '../../../../entities/apple-pay';
import { PAYMENT_METHODS } from '../../../../entities/payment-method';
import { usePersonalProfileQuery } from '../../../../entities/personal-profile';
import { ReviewCard } from '../../../../entities/review-card';
import { useCurrencySignQuery } from '../../../../hooks/use-currency-sign-query';
import { useLocationQuery } from '../../../../hooks/use-location-query';
import { analyticsService } from '../../../../services/analytics.service';
import { isAndroid, isIOS16OrLater } from '../../../../shared/lib';
import { currencySign as currencySignMapping } from '../../../../types/Currency';
import { formatPrice } from '../../../../utils/price';
import { Loader } from '../../../shared';
import CustomSpinner from '../../../shared/CustomSpinner/CustomSpinner';
import { UiPrimaryButton } from '../../../shared/ui';
import { UpsellAnalytics } from '../../shared/analytics';
import { useWebToAppAndroidExperiment } from '../../shared/hooks/use-web-to-app-android-experiment';
import { useWebToAppExperiment } from '../../shared/hooks/use-web-to-app-experiment';
import { UiHeader } from '../../shared/ui';
import { useBuyUpsellOfferMutation, useUpsellSingleQuery } from '../model';
import { ChaseBottomsheet } from './ChaseBottomsheet';
import ChatIcon from './assets/chat-icon.svg';
import FileEditIcon from './assets/file-edit-icon.svg';
import PlayIcon from './assets/play-icon.svg';
import ProfileIcon from './assets/profile-icon.svg';
import SparkleIcon from './assets/sparkle-icon.svg';
import TimeIcon from './assets/time-icon.svg';
import './assets/upsell-giga.css';
import './assets/upsell.css';

enum UpsellType {
  GIGA = 'giga_upsell',
  REMOTE_WORK = 'remote_work_upsell',
  INFLUENCER = 'influencer_upsell',
}

interface UpsellConfigId {
  configId: string;
}

function UpsellReviews({ configId }: UpsellConfigId) {
  const { t } = useTranslation(undefined, {
    keyPrefix: `upsell-single-giga.${configId}`,
  });

  const reviewsList = t('reviews.reviews', {
    returnObjects: true,
  }) as Array<{ reviewerName: string; reviewText: string }>;

  return (
    <div className="upsell__reviews upsell-giga__reviews">
      <h3 className="upsell__reviews-title">{t('reviews.title')}</h3>

      <div className="upsell__reviews-list">
        {reviewsList.map((item, i) => (
          <ReviewCard
            key={`upsell-review__${i}`}
            name={item.reviewerName}
            text={item.reviewText}
            className="upsell-giga__review-card"
          />
        ))}
      </div>
    </div>
  );
}

function UpsellBillingDescription(props: {
  hasVat: boolean;
  discountedPrice: string;
  configId: string;
}) {
  const { t } = useTranslation(undefined, {
    keyPrefix: `upsell-single-giga.${props.configId}`,
  });

  return (
    <div className="upsell__billing-description">
      <Trans
        i18nKey={
          props.hasVat
            ? t('billing-description-with-vat')
            : t('billing-description')
        }
        values={{ discountPrice: props.discountedPrice }}
        components={{ b: <b /> }}
      />
    </div>
  );
}

function UpsellBenefitsBlock({ configId }: UpsellConfigId) {
  const { t } = useTranslation(undefined, {
    keyPrefix: `upsell-single-giga.${configId}`,
  });

  const isRemoteWork = configId === UpsellType.REMOTE_WORK;
  const isInfluencer = configId === UpsellType.INFLUENCER;

  const benefitsList = ['maximize', 'save-time', 'copy-paste'];

  const getIconSrc = (benefitType: string) => {
    switch (benefitType) {
      case 'maximize':
        return isInfluencer
          ? PlayIcon
          : isRemoteWork
            ? FileEditIcon
            : SparkleIcon;
      case 'save-time':
        return isInfluencer
          ? FileEditIcon
          : isRemoteWork
            ? ProfileIcon
            : TimeIcon;
      case 'copy-paste':
        return isInfluencer || isRemoteWork ? ChatIcon : FileEditIcon;
      default:
        return SparkleIcon;
    }
  };

  return (
    <div className="upsell-giga__benefits">
      <div className="upsell-giga__benefits-image">
        <img
          width="312"
          height="252"
          src={t('benefits.image') as string}
          role="presentation"
          alt=""
        />
      </div>

      <ul className="upsell-giga__benefits-list">
        {benefitsList.map(benefitType => (
          <li className="upsell-giga__benefits-item" key={benefitType}>
            <img src={getIconSrc(benefitType)} alt="" width="24" height="24" />
            <span>
              <Trans
                i18nKey={t(`benefits.${benefitType}`)}
                components={{ b: <b /> }}
              />
            </span>
          </li>
        ))}
      </ul>

      <div className="upsell-giga__gift">
        <img
          width="64"
          height="64"
          src={t('gift.image') as string}
          role="presentation"
          alt=""
        />

        <div className="upsell-giga__gift-content">
          <h5>{t('gift.title')}</h5>
          <h3>{t('gift.subtitle')}</h3>
        </div>
      </div>
    </div>
  );
}

function useRefCallback() {
  const [ref, setRef] = useState<HTMLDivElement | null>(null);

  const setRefCallback = useCallback(node => {
    if (node !== null) {
      setRef(node);
    }
  }, []);

  return {
    ref,
    setRefCallback,
  };
}

export function UpsellSingleGigaPage() {
  const navigation = useHistory();
  const isWebToAppScreenShow = useFeatureIsOn('ios_web2app_screen_show');

  const params = useParams<{
    paywallConfigId: string;
    from: string;
  }>();
  const { paywallConfigId } = params;

  const locationQuery = useLocationQuery();
  const fromQuery = locationQuery.get('from');
  const assistantQuery = locationQuery.get('assistant');

  const assistantTestValue = assistantQuery ?? 'default';

  const userPersonalProfile = usePersonalProfileQuery(true);
  const userData = useRecoilValue(userDataQuery);
  const arrow = userPersonalProfile?.data?.personalization_criteria?.arrow?.[0];
  const isArrowAi = arrow === 'ai';

  const growthBook = useGrowthBook();

  const growthBookUpsellGigaValue = useFeatureValue(
    'exp_upsell_megaupsell',
    'default'
  );

  const growthbookHeadingExpValue = useFeatureValue(
    'headings-feature',
    'default'
  );

  const { t } = useTranslation(undefined, {
    keyPrefix: `upsell-single-giga.${paywallConfigId}`,
  });

  const upsellExperiment = 'clean';

  const webToAppExperiment = useWebToAppExperiment();
  const androidWebToAppExperiment = useWebToAppAndroidExperiment();

  const isGigaUpsell = paywallConfigId === UpsellType.GIGA;
  const isRemoteWorkTest = false;
  const isInfluencerTest = false;

  const influencerState =
    isGigaUpsell && fromQuery === UpsellType.INFLUENCER ? 'clean' : 'default';

  const remoteWorkState =
    isGigaUpsell && fromQuery === UpsellType.REMOTE_WORK ? 'clean' : 'default';

  const remoteWorkAnalyticsState = isRemoteWorkTest
    ? 'test'
    : paywallConfigId === UpsellType.REMOTE_WORK && upsellExperiment === 'clean'
      ? 'clean'
      : 'default';

  const influencerAnalyticsState = isInfluencerTest
    ? 'test'
    : paywallConfigId === UpsellType.INFLUENCER && upsellExperiment === 'clean'
      ? 'clean'
      : 'default';

  const remoteWorkAnalyticsValue = isGigaUpsell
    ? remoteWorkState
    : remoteWorkAnalyticsState;

  const influencerAnalyticsValue = isGigaUpsell
    ? influencerState
    : influencerAnalyticsState;

  const [isUpsellBottomSheetVisible, setIsUpsellBottomSheetVisible] =
    useState(false);

  const [isDiscountUpsell, setIsDiscountUpsell] = useState(false);

  const { ref: topPricingRef, setRefCallback: setTopPricingRef } =
    useRefCallback();

  const { ref: bottomPricingRef, setRefCallback: setBottomPricingRef } =
    useRefCallback();

  const [isTopBenefitsVisible, setIsTopBenefitsVisible] = useState(false);

  const [isBottomBenefitsVisible, setIsBottomBenefitsVisible] = useState(false);

  const [isAboveThanTopBenefitsBlock, setIsAboveThanTopBenefitsBlock] =
    useState(false);

  const {
    lastPaymentMethodQuery,
    isApplePayAvailableInSystem,
    isLastPaymentMethodApplePay,
    isApplePay,
    isOneClick,
  } = useApplePay();

  const upsellOfferSingleQuery = useUpsellSingleQuery({
    paywallConfigId: paywallConfigId,
  });

  const currencySignQuery = useCurrencySignQuery();

  const currencySign = useMemo(() => {
    return upsellOfferSingleQuery.data
      ? upsellOfferSingleQuery.data.currency === 'USD'
        ? currencySignQuery.data
        : currencySignMapping[upsellOfferSingleQuery.data.currency]
      : '$';
  }, [upsellOfferSingleQuery]);

  const initialPricing = useMemo(() => {
    if (upsellOfferSingleQuery.data) {
      return upsellOfferSingleQuery.data.initialPrice;
    }
  }, [upsellOfferSingleQuery.data]);

  const pricing = useMemo(() => {
    if (upsellOfferSingleQuery.data) {
      return isDiscountUpsell
        ? upsellOfferSingleQuery.data.discountedPrice
        : upsellOfferSingleQuery.data.initialPrice;
    }

    return null;
  }, [upsellOfferSingleQuery.data, isDiscountUpsell]);

  const isUpsellAvailable = useMemo(
    () => (isLastPaymentMethodApplePay ? isApplePayAvailableInSystem : true),
    [isLastPaymentMethodApplePay]
  );
  const upsellOfferSingleMutation = useBuyUpsellOfferMutation();

  const fullPrice = useMemo(
    () =>
      pricing && currencySign
        ? `${currencySign}${formatPrice(pricing.original_pricing.full_price)}`
        : '',
    [pricing, currencySign]
  );

  const absoluteDiscountPrice = useMemo(
    () =>
      pricing && currencySign
        ? `${currencySign}${formatPrice(pricing.discount_price)}`
        : '',
    [pricing, currencySign]
  );

  const discountedPrice = useMemo(
    () =>
      pricing && currencySign
        ? `${currencySign}${formatPrice(
            pricing.original_pricing.discount_price
          )}`
        : '',
    [pricing, currencySign]
  );

  const vatPrice = useMemo(
    () =>
      pricing && currencySign
        ? `${currencySign}${formatPrice(
            pricing.original_pricing.discount_price
          )} + ${currencySign}${formatPrice(
            pricing.discount_price_vat_amount
          )} VAT`
        : '',
    [pricing, currencySign]);

  const currencyCode = useMemo(
    () =>
      upsellOfferSingleQuery.data ? upsellOfferSingleQuery.data.currency : '',
    [upsellOfferSingleQuery.data]
  );

  const hasVat = useMemo(() => Boolean(pricing && pricing.vat > 0), [pricing]);

  const isOfferPaywallConfig = paywallConfigId.includes('_single');

  function goToNextPage(isPurchased = false) {
    window.scroll(0, 0);

    const basePath = '/upsell-cascade';

    const queryParams = new URLSearchParams();
    if (isPurchased) {
      queryParams.append('isReportOfferAdded', 'true');
    }

    if (fromQuery) {
      queryParams.append('arrow', fromQuery);
    }

    if (assistantQuery) {
      queryParams.append('assistant', assistantQuery);
    }

    const path = generatePath(basePath, {
      assistant: assistantQuery || undefined,
    });
    const fullPath = `${path}${
      queryParams.toString() ? `?${queryParams.toString()}` : ''
    }`;

    return navigation.push(fullPath);
  }

  function onSkip() {
    UpsellAnalytics.onSkip({
      position: 'top',
      place: 'signup_gigaupsell',
      paywall_config_id: isOfferPaywallConfig
        ? 'offer_single'
        : 'upsells_notion_report',
      paymentMethod: lastPaymentMethodQuery.data?.payment_type ?? 'error',
      exp_upsell_megaupsell: growthBookUpsellGigaValue,
      is_oneclick: isOneClick,
      remote_work: remoteWorkAnalyticsValue,
      influencer: influencerAnalyticsValue,
      assistant: isArrowAi ? 'default' : assistantTestValue,
      business: isArrowAi ? assistantTestValue : 'default',
      headingExpValue: growthbookHeadingExpValue,
    });

    if (isDiscountUpsell) {
      return goToNextPage();
    }

    setIsUpsellBottomSheetVisible(true);
  }

  function onShowDiscountedUpsell() {
    setIsUpsellBottomSheetVisible(false);
    setIsDiscountUpsell(true);
    window.scroll(0, 0);
  }

  useEffect(() => {
    if (topPricingRef && bottomPricingRef) {
      const intersectionObserver = new IntersectionObserver(
        intersectionObserverCallback,
        {
          root: null,
          rootMargin: '0px',
          threshold: 1.0,
        }
      );

      intersectionObserver.observe(topPricingRef);
      intersectionObserver.observe(bottomPricingRef);

      return () => {
        intersectionObserver.disconnect();
      };
    }
  }, [topPricingRef, bottomPricingRef]);

  function intersectionObserverCallback(entries: IntersectionObserverEntry[]) {
    entries.forEach(entry => {
      if (entry.target === topPricingRef) {
        setIsAboveThanTopBenefitsBlock(entry.boundingClientRect.top > 0);
        setIsTopBenefitsVisible(entry.isIntersecting);
      }

      if (entry.target === bottomPricingRef) {
        setIsBottomBenefitsVisible(entry.isIntersecting);
      }
    });
  }

  function onPurchaseClick() {
    if ((isTopBenefitsVisible || isBottomBenefitsVisible) && !isApplePay) {
      return onPurchaseNewUpsell();
    }

    const scrollToTopBlock = () => {
      return window.scrollTo({
        top: isDiscountUpsell ? 850 : 650, //@description - topPricingRef top - 200
        behavior: 'smooth',
      });
    };

    const scrollToBottomBlock = () => {
      if (bottomPricingRef) {
        return bottomPricingRef.scrollIntoView({
          behavior: 'smooth',
        });
      }
    };

    if (isAboveThanTopBenefitsBlock && !isApplePay) {
      return scrollToTopBlock();
    }

    if (!isAboveThanTopBenefitsBlock && !isApplePay) {
      return scrollToBottomBlock();
    }

    if (isApplePay) {
      return scrollToTopBlock();
    }
  }

  function onPurchaseNewUpsell() {
    if (upsellOfferSingleQuery.data && pricing) {
      UpsellAnalytics.onTryToPay({
        place: 'signup_gigaupsell',
        pricingId: pricing.id,
        offerId: upsellOfferSingleQuery.data.id,
        price: pricing.discount_price,
        currency: currencyCode,
        paywall_config_id: isOfferPaywallConfig
          ? 'offer_single'
          : 'upsells_notion_report',
        paymentMethod: lastPaymentMethodQuery.data?.payment_type ?? 'error',
        exp_upsell_megaupsell: growthBookUpsellGigaValue,
        is_oneclick: isOneClick,
        remote_work: remoteWorkAnalyticsValue,
        influencer: influencerAnalyticsValue,
        assistant: isArrowAi ? 'default' : assistantTestValue,
        business: isArrowAi ? assistantTestValue : 'default',
        headingExpValue: growthbookHeadingExpValue,
      });

      upsellOfferSingleMutation
        .mutateAsync({
          offerId: upsellOfferSingleQuery.data.id,
          paywallConfigId,
          pricingId: pricing.id,
        })
        .catch(cause => {
          throw new Error('ERROR_PURCHASE_NEW_UPSELL', { cause });
        })
        .finally(() => {
          setTimeout(() => {
            goToNextPage(true);
          }, 500);
        });
    }
  }

  function onApplePaySuccess() {
    setTimeout(() => {
      goToNextPage(true);
    }, 500);
  }

  function onApplePayTryToPay() {
    if (upsellOfferSingleQuery.data && pricing) {
      UpsellAnalytics.onTryToPay({
        place: 'signup_gigaupsell',
        pricingId: pricing.id,
        offerId: upsellOfferSingleQuery.data.id,
        price: pricing.discount_price,
        currency: currencyCode,
        paywall_config_id: isOfferPaywallConfig
          ? 'offer_single'
          : 'upsells_notion_report',
        paymentMethod: PAYMENT_METHODS.APPLE_PAY,
        exp_upsell_megaupsell: growthBookUpsellGigaValue,
        is_oneclick: isOneClick,
        remote_work: remoteWorkAnalyticsValue,
        influencer: influencerAnalyticsValue,
        assistant: isArrowAi ? 'default' : assistantTestValue,
        business: isArrowAi ? assistantTestValue : 'default',
        headingExpValue: growthbookHeadingExpValue,
      });
    }
  }

  function onApplePayLoaderClick() {
    if (upsellOfferSingleQuery.data && pricing) {
      UpsellAnalytics.onApplePayLoaderClick({
        place: 'signup_gigaupsell',
        pricingId: pricing.id,
        offerId: upsellOfferSingleQuery.data.id,
        price: pricing.discount_price,
        currency: currencyCode,
        paywall_config_id: isOfferPaywallConfig
          ? 'offer_single'
          : 'upsells_notion_report',
        exp_upsell_megaupsell: growthBookUpsellGigaValue,
        is_oneclick: isOneClick,
        remote_work: remoteWorkAnalyticsValue,
        influencer: influencerAnalyticsValue,
        assistant: isArrowAi ? 'default' : assistantTestValue,
        business: isArrowAi ? assistantTestValue : 'default',
        headingExpValue: growthbookHeadingExpValue,
      });
    }
  }

  const SELECTION_OPTIONS = [
    {
      title: t('selection-options.first.title'),
      description: t('selection-options.first.description'),
      image: t('selection-options.first.image') as string,
    },
    {
      title: t('selection-options.second.title'),
      description: t('selection-options.second.description'),
      image: t('selection-options.second.image') as string,
    },
    {
      title: t('selection-options.third.title'),
      description: t('selection-options.third.description'),
      image: t('selection-options.third.image') as string,
    },
  ];

  const STEPS = [
    {
      title: t('steps.first.title'),
      description: t('steps.first.description'),
    },
    {
      title: t('steps.second.title'),
      description: t('steps.second.description'),
    },
    {
      title: t('steps.third.title'),
      description: t('steps.third.description'),
    },
  ];

  useEffect(() => {
    if (
      upsellOfferSingleQuery.data &&
      pricing &&
      !lastPaymentMethodQuery.isLoading &&
      isUpsellAvailable
    ) {
      UpsellAnalytics.pageView({
        place: 'signup_gigaupsell',
        offerId: upsellOfferSingleQuery.data.id,
        pricingId: pricing.id,
        price: pricing.discount_price,
        currency: currencyCode,
        paywall_config_id: isOfferPaywallConfig
          ? 'offer_single'
          : 'upsells_notion_report',
        paymentMethod: lastPaymentMethodQuery.data?.payment_type ?? 'error',
        exp_upsell_megaupsell: growthBookUpsellGigaValue,
        exp_upsell_upsell_cascade_redesign: false,
        is_oneclick: isOneClick,
        remote_work: remoteWorkAnalyticsValue,
        influencer: influencerAnalyticsValue,
        assistant: isArrowAi ? 'default' : assistantTestValue,
        business: isArrowAi ? assistantTestValue : 'default',
        headingExpValue: growthbookHeadingExpValue,
      });

      UpsellAnalytics.legacyPageView({
        price: pricing.discount_price,
        currency: currencyCode,
        isDiscountUpsell,
        isOneClick,
        remoteWork: remoteWorkAnalyticsValue,
        influencer: influencerAnalyticsValue,
        assistant: isArrowAi ? 'default' : assistantTestValue,
        business: isArrowAi ? assistantTestValue : 'default',
        headingExpValue: growthbookHeadingExpValue,
      });
    }
  }, [
    upsellOfferSingleQuery.data,
    pricing,
    lastPaymentMethodQuery.isLoading,
    isUpsellAvailable,
  ]);

  useEffect(() => {
    if (!lastPaymentMethodQuery.isLoading && !isUpsellAvailable) {
      UpsellAnalytics.onAllUpsellSkip({
        exp_upsell_megaupsell: growthBookUpsellGigaValue,
        is_oneclick: isOneClick,
        remote_work: remoteWorkAnalyticsValue,
        influencer: influencerAnalyticsValue,
        assistant: isArrowAi ? 'default' : assistantTestValue,
        business: isArrowAi ? assistantTestValue : 'default',
        headingExpValue: growthbookHeadingExpValue,
      });

      goToNextPage();
    }
  }, [isUpsellAvailable, lastPaymentMethodQuery.isLoading]);

  useEffect(() => {
    if (paywallConfigId !== 'giga_upsell' && upsellExperiment === 'clean') {
      navigation.replace(
        `/upsell-single-giga/giga_upsell?from=${paywallConfigId}&assistant=${assistantQuery}`
      );
    }
  }, []);

  useEffect(() => {
    if (isIOS16OrLater() && isWebToAppScreenShow) {
      analyticsService.setAmplitudeUserProperties({
        exp_web2a_v2: webToAppExperiment,
      });
    }
  }, [webToAppExperiment]);

  useEffect(() => {
    if (isAndroid()) {
      analyticsService.setAmplitudeUserProperties({
        exp_web2a_android_v1: androidWebToAppExperiment,
      });
    }
  }, [androidWebToAppExperiment]);

  useEffect(() => {
    upsellOfferSingleQuery.refetch();
  }, [paywallConfigId]);

  useEffect(() => {
    if (!userPersonalProfile.data || !userData) {
      return;
    }

    growthBook?.setAttributes({
      ...growthBook?.getAttributes(),
      registered_at: dayjs(userData?.registered_at).format('YYYY-MM-DDTHH:mm'),
      arrow: userPersonalProfile.data.personalization_criteria?.arrow?.[0],
    });
  }, [userPersonalProfile.data, userData]);

  if (lastPaymentMethodQuery.isLoading || !isUpsellAvailable) {
    return <Loader />;
  }

  return (
    <>
      {upsellOfferSingleMutation.isLoading && <CustomSpinner />}

      <main className="upsell vh-full">
        <UiHeader
          events={{
            onSkip: () => onSkip(),
          }}
        />

        <div className="upsell__content upsell-giga__content">
          {isDiscountUpsell && pricing && initialPricing && (
            <div className="upsell-giga__discount">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="56"
                height="56"
                fill="none"
              >
                <rect width="56" height="56" fill="#5653FE" rx="28" />
                <path
                  fill="#EEF"
                  fillRule="evenodd"
                  d="M17.938 16.125A5.687 5.687 0 0 1 28 12.49a5.687 5.687 0 0 1 8.858 7.135H38.5A4.376 4.376 0 0 1 42.875 24v2.187a1.313 1.313 0 0 1-1.313 1.313H29.838a.525.525 0 0 1-.526-.525V20.92A5.716 5.716 0 0 1 28 19.76a5.715 5.715 0 0 1-1.313 1.158v6.057a.525.525 0 0 1-.524.525H14.437a1.313 1.313 0 0 1-1.312-1.313V24a4.376 4.376 0 0 1 4.375-4.375h1.642a5.663 5.663 0 0 1-1.204-3.5Zm8.75 0a3.063 3.063 0 1 0-6.126 0 3.063 3.063 0 0 0 6.125 0Zm2.625 0a3.063 3.063 0 1 0 6.125 0 3.063 3.063 0 0 0-6.126 0Z"
                  clipRule="evenodd"
                />
                <path
                  fill="#EEF"
                  d="M26.688 30.387a.525.525 0 0 0-.525-.524h-9.277a1.459 1.459 0 0 0-1.435 1.21 20.283 20.283 0 0 0 0 6.853l.392 2.291a3.513 3.513 0 0 0 3.071 2.898l1.864.209c1.785.2 3.578.325 5.374.376a.52.52 0 0 0 .535-.524V30.387Zm3.16 13.313a.52.52 0 0 1-.535-.524V30.387a.525.525 0 0 1 .524-.524h9.277c.71 0 1.316.51 1.435 1.21.39 2.269.39 4.585 0 6.853l-.39 2.291a3.514 3.514 0 0 1-3.073 2.898l-1.864.209c-1.785.2-3.578.325-5.374.376Z"
                />
              </svg>

              <div className="upsell-giga__discount-content">
                <h6 className="upsell-giga__discount-subtitle">
                  <Trans
                    i18nKey={t('discount.subtitle', {
                      discount: initialPricing.discount,
                    })}
                    components={{
                      b: <b />,
                    }}
                  />
                </h6>
                <h5 className="upsell-giga__discount-title">
                  {t('discount.title', {
                    discount: pricing.discount,
                  })}
                </h5>
              </div>
            </div>
          )}
          <h1
            className="upsell__title upsell-giga__title"
            dangerouslySetInnerHTML={{
              __html: t(`${growthbookHeadingExpValue}.title`),
            }}
          ></h1>
          {/* change title here */}
          <h2
            className="upsell-giga__subtitle"
            dangerouslySetInnerHTML={{ __html: t('subtitle') }}
          ></h2>
          <UpsellBenefitsBlock configId={paywallConfigId} />
          <div ref={setTopPricingRef}>
            <h2 className="upsell__title upsell-giga__title">
              {t('pricing.title')}
            </h2>

            <div className="upsell-giga__price">
              <div className="upsell-giga__price-label">
                {t(`${growthbookHeadingExpValue}.pricing.first`, {
                  defaultValue: t('pricing.first'),
                })}
              </div>
              <div className="upsell-giga__price-content">
                <div className="upsell-giga__price-amount">
                  {discountedPrice}
                </div>
                <div className="upsell-giga__price-amount upsell-giga__price-strike">
                  {fullPrice}
                </div>
              </div>
            </div>

            <div className="upsell-giga__price">
              <div className="upsell-giga__price-label">
                {t(`${growthbookHeadingExpValue}.pricing.second`, {
                  defaultValue: t('pricing.second'),
                })}
              </div>
              <div className="upsell-giga__price-content">
                <div className="upsell-giga__price-free">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="13"
                    fill="none"
                  >
                    <path
                      fill="#F25151"
                      d="M5.167 1.151A.293.293 0 0 1 5.221.91.282.282 0 0 1 5.44.8c.044.001.088.012.129.032.342.137 2.694 1.266 2.15 4.532.79-.485.507-1.426.766-1.426.097 0 .136.037.196.123.864 1.256 1.518 2.754 1.518 4.156a4.327 4.327 0 0 1-1.23 3.028A4.159 4.159 0 0 1 6 12.5a4.159 4.159 0 0 1-2.97-1.255A4.327 4.327 0 0 1 1.8 8.217c0-3.477 4.022-4.118 3.367-7.066Z"
                    />
                    <path
                      fill="#FFA1A1"
                      d="M5.893 5.429a.315.315 0 0 0-.174-.054.3.3 0 0 0-.199.073.237.237 0 0 0-.082.177c.28 1.5-1.688 2-1.688 3.749 0 .53.237 1.04.659 1.415A2.4 2.4 0 0 0 6 11.375a2.4 2.4 0 0 0 1.591-.586c.422-.376.659-.885.659-1.415 0-1.425-1.412-3.326-2.357-3.945Z"
                    />
                  </svg>
                  <span>{t('pricing.free')}</span>
                </div>

                <div className="upsell-giga__price-amount upsell-giga__price-strike">
                  {currencySign}19.99
                </div>
              </div>
            </div>
            {hasVat && (
              <div className="upsell-giga__price">
                <div className="upsell-giga__price-label">{t('total')}</div>
                <div className="upsell-giga__price-content">
                  <div className="upsell-giga__price-amount-gray">
                    {vatPrice}
                  </div>
                </div>
              </div>
            )}

            <UpsellBillingDescription
              configId={paywallConfigId}
              hasVat={hasVat}
              discountedPrice={absoluteDiscountPrice}
            />

            {upsellOfferSingleQuery.data && pricing && (
              <div className="upsell_purchase-button upsell-giga_purchase-button">
                {isApplePay ? (
                  <ApplePayButton
                    offerId={upsellOfferSingleQuery.data.id}
                    pricingId={pricing.id}
                    paywallConfigId={paywallConfigId}
                    events={{
                      onTryToPay: onApplePayTryToPay,
                      onLoaderClick: onApplePayLoaderClick,
                      onSuccess: onApplePaySuccess,
                    }}
                  />
                ) : (
                  <UiPrimaryButton
                    className="upsell__add-button"
                    onClick={onPurchaseNewUpsell}
                  >
                    {t('purchase-button')}
                  </UiPrimaryButton>
                )}
              </div>
            )}
          </div>
          <h3 className="upsell__title upsell-giga__title upsell-giga__selections-title">
            {t('selection-options.title')}
          </h3>
          <div className="upsell-giga__selections-options">
            {SELECTION_OPTIONS.map((option, i) => (
              <div
                key={`upsell-giga-option-${i}`}
                className="upsell-giga__option"
              >
                <div className="upsell-giga__option-content">
                  <h5 className="upsell-giga__option-title">{option.title}</h5>
                  <p className="upsell-giga__option-description">
                    {option.description}
                  </p>
                </div>

                <div className="upsell-giga__option-image">
                  <img
                    width="312"
                    height="256"
                    src={option.image}
                    role="presentation"
                    alt=""
                  />
                </div>
              </div>
            ))}
          </div>
          <h3 className="upsell__title upsell-giga__title">
            {t('steps.title')}
          </h3>
          <div className="upsell-giga__steps">
            {STEPS.map((step, i) => (
              <div key={`upsell-giga-step-${i}`} className="upsell-giga__step">
                <h6 className="upsell-giga__step-counter">
                  {t('steps.counter', { count: i + 1 })}
                </h6>
                <h4 className="upsell-giga__step-title">{step.title}</h4>
                <p className="upsell-giga__step-description">
                  {step.description}
                </p>
              </div>
            ))}
          </div>
          <UpsellReviews configId={paywallConfigId} />
          <h3 className="upsell__title upsell-giga__title">
            {t('bottom.title')}
          </h3>
          <h4 className="upsell-giga__subtitle">{t('bottom.subtitle')}</h4>
          <UpsellBenefitsBlock configId={paywallConfigId} />
          <div ref={setBottomPricingRef}>
            <h2 className="upsell__title upsell-giga__title">
              {t('pricing.title')}
            </h2>

            <div className="upsell-giga__price">
              <div className="upsell-giga__price-label">
                {t(`${growthbookHeadingExpValue}.pricing.first`, {
                  defaultValue: t('pricing.first'),
                })}
              </div>
              <div className="upsell-giga__price-content">
                <div className="upsell-giga__price-amount">
                  {discountedPrice}
                </div>
                <div className="upsell-giga__price-amount upsell-giga__price-strike">
                  {fullPrice}
                </div>
              </div>
            </div>

            <div className="upsell-giga__price">
              <div className="upsell-giga__price-label">
                {t(`${growthbookHeadingExpValue}.pricing.second`, {
                  defaultValue: t('pricing.second'),
                })}
              </div>
              <div className="upsell-giga__price-content">
                <div className="upsell-giga__price-free">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="13"
                    fill="none"
                  >
                    <path
                      fill="#F25151"
                      d="M5.167 1.151A.293.293 0 0 1 5.221.91.282.282 0 0 1 5.44.8c.044.001.088.012.129.032.342.137 2.694 1.266 2.15 4.532.79-.485.507-1.426.766-1.426.097 0 .136.037.196.123.864 1.256 1.518 2.754 1.518 4.156a4.327 4.327 0 0 1-1.23 3.028A4.159 4.159 0 0 1 6 12.5a4.159 4.159 0 0 1-2.97-1.255A4.327 4.327 0 0 1 1.8 8.217c0-3.477 4.022-4.118 3.367-7.066Z"
                    />
                    <path
                      fill="#FFA1A1"
                      d="M5.893 5.429a.315.315 0 0 0-.174-.054.3.3 0 0 0-.199.073.237.237 0 0 0-.082.177c.28 1.5-1.688 2-1.688 3.749 0 .53.237 1.04.659 1.415A2.4 2.4 0 0 0 6 11.375a2.4 2.4 0 0 0 1.591-.586c.422-.376.659-.885.659-1.415 0-1.425-1.412-3.326-2.357-3.945Z"
                    />
                  </svg>
                  <span>{t('pricing.free')}</span>
                </div>

                <div className="upsell-giga__price-amount upsell-giga__price-strike">
                  {currencySign}19.99
                </div>
              </div>
            </div>

            {hasVat && (
              <div className="upsell-giga__price">
                <div className="upsell-giga__price-label">{t('total')}</div>
                <div className="upsell-giga__price-content">
                  <div className="upsell-giga__price-amount-gray">
                    {vatPrice}
                  </div>
                </div>
              </div>
            )}

            <UpsellBillingDescription
              configId={paywallConfigId}
              hasVat={hasVat}
              discountedPrice={absoluteDiscountPrice}
            />
          </div>
          {!isTopBenefitsVisible && upsellOfferSingleQuery.data && pricing && (
            <div className="upsell_purchase-button upsell-giga_purchase-bottom-button">
              <UiPrimaryButton
                className="upsell__add-button"
                onClick={onPurchaseClick}
              >
                {t('purchase-button')}
              </UiPrimaryButton>
            </div>
          )}
        </div>
      </main>

      <ChaseBottomsheet
        isVisible={isUpsellBottomSheetVisible}
        events={{
          onClose: onShowDiscountedUpsell,
        }}
        upsellType={paywallConfigId}
        headingExpValue={growthbookHeadingExpValue}
      />
    </>
  );
}
