import { useQuery } from '@tanstack/react-query';

import { AiBotsListAPI } from '../api';

export function useAiBotsListQuery() {
  return useQuery({
    queryKey: ['ai-bots-list'],
    /**
     *  TODO:
     *    можно сделать prefetch для деталей отдельных чат ботов
     *    https://tanstack.com/query/latest/docs/react/reference/QueryClient#queryclientsetquerydata
     * */
    queryFn: () => AiBotsListAPI.getAiBots().then(({ data }) => data),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
}
