import http from '../services/http.service';

export const onboardingApi = {
  getOnboardingContent: async (lang: string, arrow: string) => {
    return http
      .get(
        `https://d2h7i1o4snhir4.cloudfront.net/onboardings/onboarding_${arrow}_${lang}.json`
      )
      .then(res => {
        return res.data;
      })
      .catch(() => {
        return;
      });
  },
};
