import type { AiBot } from '../../ai-bot';
import './ai-bot-card.css';

export function AiBotCard({
  data,
  onClick,
}: {
  data: AiBot;
  onClick?: () => void;
}) {
  return (
    <div className="ai-bot-card" onClick={onClick}>
      <img
        className="ai-bot-card__image"
        width="64"
        height="64"
        src={data.image || ''} //@note For custom bots add default image (or make image required)
        role="presentation"
        alt=""
      />
      <div className="ai-bot-card__content">
        <h2>{data.name}</h2>
        <p className="ai-bot-card__description">{data.description}</p>
      </div>
    </div>
  );
}
