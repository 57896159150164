import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { usePersonalProfileQuery } from '../../../../../entities/personal-profile';
import i18n from '../../../../../i18n';
import { getAllowedArrow } from '../../../../upsell/upsell-cascade/lib';
import { OnboardingV2Analytics } from '../../../shared/analytics';
import { useLottieAiExperiment } from '../../../shared/hooks/use-lottie-ai-experiment';
import { useOnboardingContent } from '../hooks/use-onboarding-content';
import { AiToolCard } from './AiToolCard';
import './assets/ai-tool-page.css';

interface PageContentItem {
  type: string;
  value: any;
}

interface AiTool {
  icon: string;
  name: string;
  description: string;
}

export function AiToolPage() {
  const navigation = useHistory();
  const [selectedTool, setSelectedTool] = useState<string | null>(null);
  const userPersonalProfile = usePersonalProfileQuery(true);
  const userArrow =
    userPersonalProfile?.data?.personalization_criteria?.arrow?.[0];
  const userArrowFiltered = getAllowedArrow(userArrow, 'ai');
  const lang = i18n.language;
  const { content } = useOnboardingContent({
    arrow: userArrowFiltered || 'ai',
    lang: lang,
  });

  const lottieOnboardingExperiment = useLottieAiExperiment();

  const pageContent: PageContentItem[] = content?.['select-ai-tool'] || [];

  const title = pageContent?.find(item => item.type === 'title')?.value || '';
  const description =
    pageContent?.find(item => item.type === 'description')?.value || '';
  const aiToolsBlock =
    pageContent?.find(item => item.type === 'ai-tools-block')?.value || [];

  const toolsContent: AiTool[] = aiToolsBlock.map((tool: any) => ({
    icon: tool.value.find((item: any) => item.type === 'icon')?.value || '',
    name: tool.value.find((item: any) => item.type === 'name')?.value || '',
    description:
      tool.value.find((item: any) => item.type === 'description')?.value || '',
  }));

  function onNext(tool: AiTool) {
    setSelectedTool(tool.name);
    OnboardingV2Analytics.onToolSelect({
      tool: tool.name,
      lottie_test: lottieOnboardingExperiment,
    });
    localStorage.setItem('ai-tool', tool.name);
    setTimeout(() => {
      const path = '/onboarding/v2/start-onboarding';
      const query = userArrow ? `?arrow=${userArrow}` : '';
      navigation.push(path + query);
    }, 200);
  }

  useEffect(() => {
    OnboardingV2Analytics.onToolSelectionPageView({
      lottie_test: lottieOnboardingExperiment,
    });
  }, []);

  return (
    <motion.section
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="ai-tool-page min-vh-full"
    >
      <div className="ai-tool-page__content">
        <h1>{title}</h1>
        <p>{description}</p>
        <div className="ai-tool-page__cards">
          {toolsContent.map(tool => (
            <AiToolCard
              key={tool.name}
              tool={tool}
              isSelected={selectedTool === tool.name}
              onSelect={() => onNext(tool)}
            />
          ))}
        </div>
      </div>
    </motion.section>
  );
}
