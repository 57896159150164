import { filter, flatMap, some, uniqBy } from 'lodash-es';

import type { AiBot, AiBotCategory } from '../../../entities/ai-bot';

export function getUniqCategories(list: Array<AiBot>): Array<AiBotCategory> {
  return uniqBy(flatMap(list, 'categories'), 'id');
}

export function getSortedListByCategoryId({
  list,
  id,
}: {
  list: Array<AiBot>;
  id: AiBotCategory['id'];
}) {
  return filter(list, item => some(item.categories, c => c.id === id));
}
