import { useAutoAnimate } from '@formkit/auto-animate/react';
import { find, findIndex, reduce } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';

import { FullPageError } from '../../../../ai-bots/shared/errors';
import { UiLoadingSkeleton } from '../../../../ai-bots/shared/ui';
import '../../../../ai-bots/shared/ui/base-layout/ui/ui-base-layout.css';
import {
  GuideChunk,
  GuideLessonDetailsContentType,
  GuideLessonDetailsTaskType,
  GuideLessonDetailsType,
} from '../../../entities/guide-lesson';
import { useGuideLessonQuizStore } from '../../../entities/guide-lesson-quiz';
import { useGuideLessonTaskStore } from '../../../entities/guide-lesson-task';
import { GuideLessonDetailsFillTheGapType } from '../../../entities/guide-lesson/types';
import { FillTheGapModal } from '../../../features/fill-the-gap-modal/ui/FillTheGapModal';
import {
  GuideLessonScreen,
  GuideLessonScreenLoading,
} from '../../../features/guide-lesson-screen';
import { GuideTaskModal } from '../../../features/task-modal';
import './guide-lesson-widget.css';

export function GuideLessonWidget(props: {
  guide?: GuideLessonDetailsType;
  isError: boolean;
  isLoading: boolean;
  isFinishLessonLoading: boolean;
  lessonProgress?: string;
  events: {
    onRefetch: () => void;
    onGoBack: () => void;
    onFinishLesson: () => void;
    onOpenTask?: (props: {
      place: GuideLessonDetailsType['type'];
      pageId: GuideLessonDetailsContentType['id'];
      chunkId: GuideLessonDetailsTaskType['id'];
    }) => void;
    onLessonChunkView?: (props: {
      place: GuideLessonDetailsType['type'];
      pageId: GuideLessonDetailsContentType['id'];
      chunkId: GuideChunk['id'];
    }) => void;
    onSkipPractice?: (props: {
      place: GuideLessonDetailsType['type'];
      pageId: GuideLessonDetailsContentType['id'];
      chunkId: GuideChunk['id'];
    }) => void;
    onSelectQuizOption?: (props: {
      place: GuideLessonDetailsType['type'];
      pageId: GuideLessonDetailsContentType['id'];
      chunkId: GuideChunk['id'];
    }) => void;
    onTriggerQuizHint?: (props: {
      place: GuideLessonDetailsType['type'];
      pageId: GuideLessonDetailsContentType['id'];
      chunkId: GuideChunk['id'];
    }) => void;
    onQuizSubmit?: (props: {
      place: GuideLessonDetailsType['type'];
      pageId: GuideLessonDetailsContentType['id'];
      chunkId: GuideChunk['id'];
      isCorrect: boolean;
    }) => void;
    onTaskExerciseToastTrigger?: (props: {
      place: GuideLessonDetailsType['type'];
      pageId: GuideLessonDetailsContentType['id'];
      chunkId: GuideChunk['id'];
      isVisible: boolean;
    }) => void;
    onTaskSubmitMessage?: (props: {
      place: GuideLessonDetailsType['type'];
      pageId: GuideLessonDetailsContentType['id'];
      chunkId: GuideChunk['id'];
      isRetrySubmission: boolean;
    }) => void;
    onTaskReceivesAnswer?: (props: {
      place: GuideLessonDetailsType['type'];
      pageId: GuideLessonDetailsContentType['id'];
      chunkId: GuideChunk['id'];
      isSuccess: boolean;
      isSubmissionCorrect: boolean;
    }) => void;
  };
}) {
  const [parent] = useAutoAnimate();

  const clearGuideLessonTaskStore = useGuideLessonTaskStore(
    state => state.clear
  );
  const clearGuideLessonQuizStore = useGuideLessonQuizStore(
    state => state.clear
  );

  const [isTaskModalVisible, setIsTaskModalVisible] = useState(false);
  const [currentOpenTask, setCurrentOpenTask] =
    useState<GuideLessonDetailsTaskType>();

  const [currentFillTheGap, setCurrentFillTheGap] =
    useState<GuideLessonDetailsFillTheGapType>();
  const [isFillTheGapModalVisible, setIsFillTheGapModalVisible] =
    useState<boolean>(false);

  const [currentGuideProgressPercent, setCurrentGuideProgressPercent] =
    useState(0);

  const [currentVisibleScreenId, setCurrentVisibleScreenId] =
    useState<GuideLessonDetailsContentType['id']>();

  useEffect(() => {
    window.scroll(0, 0);

    return () => {
      clearGuideLessonTaskStore();
      clearGuideLessonQuizStore();
    };
  }, []);

  useEffect(() => {
    if (props.guide && !currentVisibleScreenId) {
      setCurrentVisibleScreenId(props.guide.content[0].id);
    }
  }, [props.guide]);

  useEffect(() => {
    setCurrentGuideProgressPercent(
      props.lessonProgress === 'completed' ? 100 : 0
    );
  }, [props.lessonProgress]);

  const chunkProgressWeight = useMemo(() => {
    if (!props.guide) {
      return 0;
    }

    const chunksCount = reduce(
      props.guide.content,
      (sum, item) => sum + item.content.length,
      0
    );

    /*
     * @description
     * When the last chunk is shown,
     * the lesson is considered completed.
     * Therefore, you need to subtract 1 for a correct calculation
     * */
    return 100 / (chunksCount - 1);
  }, [props.guide]);

  function onSaveProgress() {
    if (currentGuideProgressPercent < 100) {
      setCurrentGuideProgressPercent(v => v + chunkProgressWeight);
    }
  }

  const currentVisibleScreen = useMemo(
    () =>
      props.guide
        ? find(props.guide.content, ['id', currentVisibleScreenId])
        : null,
    [currentVisibleScreenId, props.guide]
  );

  const nextVisibleScreenId = useMemo(() => {
    if (!props.guide) {
      return null;
    }

    const currentVisibleScreenIdIndex = findIndex(props.guide.content, [
      'id',
      currentVisibleScreenId,
    ]);

    if (currentVisibleScreenIdIndex === props.guide.content.length - 1) {
      return null;
    }

    return props.guide.content[currentVisibleScreenIdIndex + 1].id;
  }, [currentVisibleScreenId, props.guide]);

  function onGoToNextScreen() {
    if (nextVisibleScreenId) {
      setCurrentVisibleScreenId(nextVisibleScreenId);
    }
  }

  function onOpenFillTheGap(task: GuideLessonDetailsFillTheGapType) {
    setCurrentFillTheGap(task);
    setIsFillTheGapModalVisible(true);

    if (props.guide && currentVisibleScreen) {
      // analytics in there
    }
  }

  function onOpenTask(task: GuideLessonDetailsTaskType) {
    setCurrentOpenTask(task);
    setIsTaskModalVisible(true);

    if (props.guide && currentVisibleScreen) {
      props.events.onOpenTask?.({
        place: props.guide.type,
        pageId: currentVisibleScreen.id,
        chunkId: task.id,
      });
    }
  }

  function onCloseTaskModal() {
    setIsTaskModalVisible(false);
    setCurrentOpenTask(undefined);
  }

  function onCloseFillTheGapModal() {
    setIsFillTheGapModalVisible(false);
    setCurrentFillTheGap(undefined);
  }

  function onLessonChunkView(chunkId: GuideChunk['id']) {
    if (props.guide && currentVisibleScreen) {
      props.events.onLessonChunkView?.({
        place: props.guide.type,
        pageId: currentVisibleScreen.id,
        chunkId,
      });
    }
  }

  function onSkipPractice(chunkId: GuideChunk['id']) {
    if (props.guide && currentVisibleScreen) {
      props.events.onSkipPractice?.({
        place: props.guide.type,
        pageId: currentVisibleScreen.id,
        chunkId,
      });
    }
  }

  function onSelectQuizOption(chunkId: GuideChunk['id']) {
    if (props.guide && currentVisibleScreen) {
      props.events.onSelectQuizOption?.({
        place: props.guide.type,
        pageId: currentVisibleScreen.id,
        chunkId,
      });
    }
  }

  function onTriggerQuizHint(chunkId: string) {
    if (props.guide && currentVisibleScreen) {
      props.events.onTriggerQuizHint?.({
        place: props.guide.type,
        pageId: currentVisibleScreen.id,
        chunkId,
      });
    }
  }

  function onQuizSubmit({
    chunkId,
    isCorrect,
  }: {
    chunkId: string;
    isCorrect: boolean;
  }) {
    if (props.guide && currentVisibleScreen) {
      props.events.onQuizSubmit?.({
        place: props.guide.type,
        pageId: currentVisibleScreen.id,
        chunkId,
        isCorrect,
      });
    }
  }

  function onTaskExerciseToastTrigger(isVisible: boolean) {
    if (props.guide && currentOpenTask && currentVisibleScreen) {
      props.events.onTaskExerciseToastTrigger?.({
        place: props.guide.type,
        pageId: currentVisibleScreen.id,
        chunkId: currentOpenTask.id,
        isVisible,
      });
    }
  }

  function onTaskSubmitMessage(isRetrySubmission: boolean) {
    if (props.guide && currentOpenTask && currentVisibleScreen) {
      props.events.onTaskSubmitMessage?.({
        place: props.guide.type,
        pageId: currentVisibleScreen.id,
        chunkId: currentOpenTask.id,
        isRetrySubmission,
      });
    }
  }

  function onTaskReceivesAnswer({
    isSuccess,
    isSubmissionCorrect,
  }: {
    isSuccess: boolean;
    isSubmissionCorrect: boolean;
  }) {
    if (props.guide && currentOpenTask && currentVisibleScreen) {
      props.events.onTaskReceivesAnswer?.({
        place: props.guide.type,
        pageId: currentVisibleScreen.id,
        chunkId: currentOpenTask.id,
        isSuccess,
        isSubmissionCorrect,
      });
    }
  }

  return (
    <>
      <main className="ui-base-layout min-vh-full guide-lesson-widget">
        <nav className="guide-lesson-widget__navigation">
          <button
            type="button"
            className="guide-lesson-widget__close-button"
            onClick={props.events.onGoBack}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              fill="none"
            >
              <path
                fill="#565B66"
                d="M9.293 9.293a1 1 0 0 1 1.414 0L16 14.586l5.293-5.293a1 1 0 1 1 1.414 1.414L17.414 16l5.293 5.293a1 1 0 0 1-1.414 1.414L16 17.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L14.586 16l-5.293-5.293a1 1 0 0 1 0-1.414Z"
              />
            </svg>
          </button>

          {props.isLoading ? (
            <UiLoadingSkeleton className="guide-lesson-widget__progress" />
          ) : (
            <div className="guide-lesson-widget__progress">
              <div
                className="guide-lesson-widget__progress-line"
                style={{ width: `${currentGuideProgressPercent}%` }}
              ></div>
            </div>
          )}
        </nav>

        <section ref={parent} className="guide-lesson-widget__content">
          {props.isError ? (
            <FullPageError
              withFooter={false}
              onRetry={props.events.onRefetch}
            />
          ) : (
            <>
              {props.isLoading ? (
                <GuideLessonScreenLoading />
              ) : (
                props.guide &&
                currentVisibleScreen && (
                  <GuideLessonScreen
                    key={currentVisibleScreen.id}
                    screen={currentVisibleScreen}
                    isFinishLoading={props.isFinishLessonLoading}
                    type={props.guide.type}
                    events={{
                      onGoToNextScreen,
                      onSaveProgress,
                      onOpenTask,
                      onOpenFillTheGap,
                      onFinishLesson: props.events.onFinishLesson,
                      onLessonChunkView,
                      onSkipPractice,
                      onSelectQuizOption,
                      onTriggerQuizHint,
                      onQuizSubmit,
                    }}
                  />
                )
              )}
            </>
          )}
        </section>
      </main>

      {props.guide &&
        isTaskModalVisible &&
        currentOpenTask &&
        currentVisibleScreen && (
          <GuideTaskModal
            guideType={props.guide.type}
            pageId={currentVisibleScreen.id}
            type={props.guide.type}
            task={currentOpenTask}
            events={{
              onClose: onCloseTaskModal,
              onExerciseToastTrigger: onTaskExerciseToastTrigger,
              onSubmitMessage: onTaskSubmitMessage,
              onReceivesAnswer: onTaskReceivesAnswer,
            }}
          />
        )}

      {props.guide &&
        isFillTheGapModalVisible &&
        currentFillTheGap &&
        currentVisibleScreen && (
          <FillTheGapModal
            guideType={props.guide.type}
            pageId={currentVisibleScreen.id}
            type={props.guide.type}
            task={currentFillTheGap}
            events={{
              onClose: onCloseFillTheGapModal,
              onExerciseToastTrigger: onTaskExerciseToastTrigger,
              onSubmitMessage: onTaskSubmitMessage,
              onReceivesAnswer: onTaskReceivesAnswer,
            }}
          />
        )}
    </>
  );
}
