import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useCurrencySignQuery } from '../../../../hooks/use-currency-sign-query';
import { currencySign as currencySignMapping } from '../../../../types/Currency';
import { MappedUpsellCascadeOffer, mapUpsellCascadeOffers } from '../lib';
import { useUpsellCascadeQuery } from './use-upsell-cascade-query';

export function useUpsellCascadeOffers(
  paywallConfigId?: string,
  isRedesign = false
) {
  const { t } = useTranslation();
  const { data: currency } = useCurrencySignQuery();

  const upsellCascadeQuery = useUpsellCascadeQuery(paywallConfigId);

  const currencySign = useMemo(() => {
    return upsellCascadeQuery.data
      ? upsellCascadeQuery.data[0].currency === 'USD'
        ? currency
        : currencySignMapping[upsellCascadeQuery.data[0].currency]
      : '$';
  }, [upsellCascadeQuery, currency]);

  const offers = useMemo<Array<MappedUpsellCascadeOffer>>(
    () =>
      upsellCascadeQuery.data
        ? mapUpsellCascadeOffers({
            offers: upsellCascadeQuery.data,
            currency: currencySign,
            forRedesign: isRedesign,
          })
        : [],
    [upsellCascadeQuery.data, t, currency]
  );

  const hasVat = offers[0]?.vat > 0;

  return {
    isLoading: upsellCascadeQuery.isLoading,
    isError: upsellCascadeQuery.isError,
    hasVat,
    offers,
    currencySign,
  };
}
