export function iOSVersion() {
  const match = navigator?.userAgent?.match(/OS (\d+)_?(\d+)?/) ?? null;
  return match !== null ? parseInt(match[1], 10) : 0;
}

export function isIOS16OrLater() {
  return iOSVersion() >= 16;
}

export function androidVersion() {
  const match = navigator?.userAgent?.match(/Android (\d+)\.?(\d+)?/) ?? null;
  return match !== null ? parseInt(match[1], 10) : 0;
}

export function isAndroid() {
  return androidVersion() >= 8;
}

export function getPlatform(
  fallback: 'ios' | 'android'
): 'ios' | 'android' | 'default' {
  if (isIOS16OrLater()) {
    return 'ios';
  }
  if (isAndroid()) {
    return 'android';
  }
  return fallback;
}
