import { motion } from 'framer-motion';
import { AnimatePresence } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { userApi } from '../../../../../api/userApi';
import {
  localOnboardState,
  userQuery,
} from '../../../../../context/atoms/User';
import CompleteImage from '../../../../../images/guides/complete.svg';
import useAiPathwayExperiment from '../../../../../shared/hooks/use-ai-pathway-experiment';
import { getQueryParamsFromURI } from '../../../../../utils/QueryParams/QueryParams';
import { UiPrimaryButton } from '../../../../shared/ui';
import { OnboardingV2Analytics } from '../../../shared/analytics';
import { useLottieAiExperiment } from '../../../shared/hooks/use-lottie-ai-experiment';
import { allowedArrows } from '../../../shared/lib';
import GPTAvatarIcon from '../../../shared/task-page/ui/assets/gpt-avatar-icon.png';
import './assets/congrats-page.css';

interface FinishPage {
  title: string;
  text: string;
  content: {
    title: string;
    rewards: {
      topic: string;
      text: string;
      badge: {
        icon: string;
        title: string;
      };
      tags: string[];
    }[];
  };
}

export function OnboardingV2CongratsPage() {
  const { t } = useTranslation();
  const user = useRecoilValue(userQuery);
  const setLocalOnboard = useSetRecoilState(localOnboardState);

  const queryParams = getQueryParamsFromURI();
  const userArrow = queryParams?.arrow;

  const arrow = allowedArrows.includes(userArrow) ? userArrow : 'main';

  const navigation = useHistory();

  const finishPage: FinishPage = t(`onboarding-redesign.${arrow}.finish-page`, {
    returnObjects: true,
  });

  const { value: aiPathwayExperiment } = useAiPathwayExperiment({
    isLoading: false,
  });
  const lottieOnboardingExperiment = useLottieAiExperiment();

  function onNext() {
    OnboardingV2Analytics.onCongratsContinue({
      pathway_test: aiPathwayExperiment,
      lottie_test: lottieOnboardingExperiment,
    });
    userApi.setOnboarded(user?.user_id as number);
    setLocalOnboard(true);
    navigation.replace(`/`, { from: 'onboarding' });
  }

  function onClose() {
    userApi.setOnboarded(user?.user_id as number);
    setLocalOnboard(true);
    navigation.replace(`/`, { from: 'onboarding' });
  }

  useEffect(() => {
    OnboardingV2Analytics.congratsPageView({
      pathway_test: aiPathwayExperiment,
      lottie_test: lottieOnboardingExperiment,
    });
  }, [aiPathwayExperiment]);

  return (
    <motion.main
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="congrats-page min-vh-full"
    >
      <div className="congrats-page__header">
        <button
          type="button"
          className="congrats-page__close-button"
          onClick={onClose}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="none"
          >
            <path
              fill="#565B66"
              d="M9.293 9.293a1 1 0 0 1 1.414 0L16 14.586l5.293-5.293a1 1 0 1 1 1.414 1.414L17.414 16l5.293 5.293a1 1 0 0 1-1.414 1.414L16 17.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L14.586 16l-5.293-5.293a1 1 0 0 1 0-1.414Z"
            />
          </svg>
        </button>
      </div>

      <div className="congrats-page__content">
        <img
          width="112"
          height="112"
          className="congrats-page__image"
          src={CompleteImage}
          alt=""
          role="presentation"
        />

        <h1 className="congrats-page__title">{finishPage.title}</h1>
        <p className="congrats-page__description">{finishPage.text}</p>
      </div>
      <Carousel
        reviews={finishPage.content.rewards.map(reward => ({
          tags: reward.tags,
          title: reward.topic,
          description: reward.text,
          ai: reward.badge.title,
        }))}
        arrow={arrow}
        title={finishPage.content.title}
      />

      <div className="congrats-page__actions">
        <UiPrimaryButton onClick={onNext}>{t('Continue')}</UiPrimaryButton>
      </div>
    </motion.main>
  );
}

interface Card {
  title: string;
  description: string;
  ai: string;
  tags: string[];
}

function Carousel({
  reviews,
  title,
  arrow,
}: {
  reviews: Card[];
  title: string;
  arrow: string;
}) {
  const [currentReviewIndex, setCurrentReviewIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);

  const currentReview = reviews[currentReviewIndex];

  useEffect(() => {
    if (isAnimating) {
      const timeoutId = setTimeout(() => {
        setCurrentReviewIndex(prevIndex => (prevIndex + 1) % reviews.length);
        setIsAnimating(false);
      }, 500);
      return () => clearTimeout(timeoutId);
    }
  }, [isAnimating]);

  useEffect(() => {
    const timerId = setInterval(() => {
      setIsAnimating(true);
    }, 3000);

    return () => {
      clearInterval(timerId);
    };
  }, []);

  const variants = {
    initial: { x: '-100%', z: 0, opacity: 0 },
    animate: { x: 0, z: 0, opacity: 1 },
    exit: { x: '100%', z: 0, opacity: 0 },
  };

  return (
    <div style={{ position: 'relative', width: '100%', height: '220px' }}>
      <div
        style={{
          position: 'absolute',
          bottom: 0,
          width: '100%',
        }}
      >
        <h3 className="congrats-page__cards__title">{title} </h3>
        <AnimatePresence custom={1}>
          <motion.div
            key={currentReview.title}
            variants={variants}
            initial="initial"
            animate="animate"
            exit="exit"
            transition={{ duration: 0.5 }}
            onAnimationComplete={() => setIsAnimating(false)}
          >
            <div className="congrats-page__card">
              <div>
                <h3>{currentReview.title}</h3>
                <p>{currentReview.description}</p>
              </div>
              <div className="congrats-page_line" />
              {arrow === 'main' && (
                <div className="congrats-page__ai_avatar">
                  <img
                    width="24"
                    height="24"
                    src={GPTAvatarIcon}
                    role="presentation"
                    alt=""
                  />
                  <p>{currentReview.ai}</p>
                </div>
              )}
              <div style={{ display: 'flex', gap: '8px', marginTop: '12px' }}>
                {currentReview.tags.map(tag => (
                  <div key={tag} className="congrats-page__tag">
                    {tag}
                  </div>
                ))}
              </div>
            </div>
          </motion.div>
        </AnimatePresence>
      </div>
      {/* </motion.div>
      </AnimatePresence> */}
    </div>
  );
}
